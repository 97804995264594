import React, {useContext, useRef} from "react";
import {Container} from "./styles";
import {AlertContext} from "../../providers/AlertContext";
import {copy2Clipboard} from "../../functions";

 export const UsersCard = ({id, name, lastname, email, role, active, created_at, profile_picture, onEdit}) => {

     const areaRef = useRef(null)
     const {infoAlert} = useContext(AlertContext)
     const date = new Date(Date.parse(created_at))

     const current_role = (role) => {
         switch (role) {
             case 'ROLE_ADMIN':
                 return "Administrador"
             case 'ROLE_SUPERVISOR':
                 return "Supervisor"
             case 'ROLE_AGENT':
                 return "Agente"
             default:
                 return ""
         }
     }

     const copy = () => {
         const message = copy2Clipboard(areaRef, "Correo")
         infoAlert(message)
     }



     return(
         <>
             <Container>
                 <div className="row">
                     <div className="col-5">
                         <img src={profile_picture} alt={name}/>
                     </div>
                     <div className="col-7">
                         <h4 title={name + " " + lastname}>{name + " " + lastname}</h4>
                         <span className="high">{current_role(role)}</span><br/>
                         <span className="from">Desde {date.toLocaleDateString("es-MX")}</span>
                     </div>
                 </div>
                 <p className={active === true ? "status green" : "status red"} />
                 <p className="email" onClick={copy}><span ref={areaRef}>{email}</span> <i className="fas fa-copy" /></p>
                 <i onClick={()=>onEdit({id, name, lastname, email, role, active})} className="fas fa-cog button-config" />
             </Container>
         </>
     )
 }