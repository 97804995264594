import React, {useContext, useState} from "react"
import Select from "react-select"

import {AlertContext} from "../providers/AlertContext"
import {useLocalStorage} from "../hooks/useLocalStorage"
import {Modal} from "../components/Modal"
import {LoadingComponent} from "../components/Loading"
import {Axios, customSelectStyles} from "../config"

const catalogPostpone = [
    { label: 'Buzon de voz', value: 'Buzon de voz'},
    { label: 'Telefono no contestan', value: 'Telefono no contestan'},
    { label: 'Telefono ocupado', value: 'Telefono ocupado'},
    { label: 'No contestan en la extension', value: 'No contestan en la extension'},
    { label: 'No se encuentra', value: 'No se encuentra'},
    { label: 'La persona esta ocupada', value: 'La persona esta ocupada'},
    { label: 'Se corto llamada', value: 'Se corto llamada'},
    { label: 'Proporciono otro teléfono', value: 'Proporciono otro teléfono'},
    { label: 'No le interesa', value: 'No le interesa'},
    { label: 'Demasiados intentos', value: 'Demasiados intentos'},
    { label: 'No compro el vehiculo', value: 'No compro el vehiculo'},
    { label: 'No le han entregado el vehiculo', value: 'No le han entregado el vehiculo'},
    { label: 'Telefono suspendido', value: 'Telefono suspendido'},
    { label: 'Telefono equivocado', value: 'Telefono equivocado'},
    { label: 'Telefono no enlaza', value: 'Telefono no enlaza'},
    { label: 'Fuera de horario', value: 'Fuera de horario'},
    { label: 'El se comunicara', value: 'El se comunicara'},
    { label: 'No conocen a la persona', value: 'No conocen a la persona'},
    { label: 'Se requiere extension', value: 'Se requiere extension'}
]

export const RateRegisterRowModal = ({showed, onClose}) => {

    const {successAlert, errorAlert} = useContext(AlertContext)
    const [register, setRegister] = useLocalStorage("register", "")
    const [loading, setLoading] = useState(false)
    const [reasonPostpone, setReasonPostpone] = useState()

    const handlePostpone = () => {
        setLoading(true)
        const data = {
            status: reasonPostpone.value,
            position: 1
        }
        Axios.put("/register-rows/" + register.id, JSON.stringify(data))
            .then((response) => {
            if (response.status === 200) {
                setRegister("")
                setLoading(false)
                successAlert("Correcto", "Registro calificado.", ()=>{window.location.reload()})
            }
        }).catch(()=>{
            setLoading(false)
            errorAlert("Error", "No se pudo calificar el registro.")
        });
    }

    return (
        <Modal show={showed} onClose={onClose} size="lg">
            {loading ? (
                <LoadingComponent/>
            ) : (
                <div>
                    <h3>Calificar registro.</h3>
                    <p>Selecciona el resultado de la llamada.</p>

                    <div className="row mt-3">
                        <div className="col-12">
                            <span><strong>Motivo:</strong></span>
                            <div className="row mt-3"/>
                            <Select
                                styles={customSelectStyles}
                                options={catalogPostpone}
                                placeholder={"Seleccionar estatus..."}
                                value={reasonPostpone}
                                onChange={e => setReasonPostpone(e)}
                            />
                        </div>
                        <div className="row mt-5"/>
                        <button className="btn-blue" onClick={handlePostpone}>
                            <i className="fas fa-pen"/> Calificar
                        </button>
                    </div>
                </div>
            )}
        </Modal>
    )
}