import styled from "styled-components";

export const ButtonParam = styled.button`
  border-radius: 20px;
  padding: 0.3rem 0.6rem;
  background: var(--orange-b);
  background: linear-gradient(90deg, rgba(255, 73, 0, 1) 0%, rgba(255, 128, 0, 1) 100%);
  -webkit-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.15);
  box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.15);
  border: none;
  color: white;
  margin-right: 1rem;
  font-size: 12px;
`

export const List = styled.div`
  background-color: white;
  -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.15);
  color: var(--dark-blue);
  font-weight: bold;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  position: relative;

  .circle {
    position: absolute;
    top: 0;
    left: -45px;
    padding: 0 10px;
    color: var(--dark-blue);
    font-weight: bold;
    font-size: 20px;
    width: 35px;
    height: 30px;
    background-color: #f0f0f0;
    border-radius: 50px;
    cursor: pointer;
  }
  
  .circle:hover {
    background-color: var(--secondary-orange);
    color: white;
  }

`

export const Span = styled.span`
  color: var(--dark-blue);
  font-weight: bold;
  padding-left: 1rem;
`

export const Ul = styled.ul`
    list-style-type: none;

  li {
    display: inline;
    margin-right: 1rem;
    color: var(--dark-blue);
    font-weight: bold;
    font-size: 18px;
  }

  i {
    color: var(--tbase-orange);
    cursor: pointer;
    font-size: 20px;
    margin-right: 12px;
  }
`