import styled from "styled-components";

export const Main = styled.main`
  margin-left: ${props => props.fullScreen ? "14rem" : "0"};
  padding-bottom: 10rem;

  @media only screen and (max-width: 768px) {
    margin-left: 0;
  }
  
`