import React, {useContext, useState} from "react"

import {HeaderComponent} from "../../components/Header"
import {ReportsGetSurvey} from "../../components/Reports/get_survey"
import {ReportsEmpty} from "../../components/Reports/empty"
import {ReportsDateSelector} from "../../components/Reports/date_selector"
import {ReportsDownload} from "../../components/Reports/download"
import {ReportsGraphs} from "../../components/Reports/graphs"

import {SessionContext} from "../../providers/SessionContext"

export const ReportsBySurveyPageComponent = () => {

    const { session } = useContext(SessionContext)
    const [surveyId, setSurveyId] = useState("")
    const [agentId, setAgentId] = useState("")
    const [surveyName, setSurveyName] = useState("")
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())

    return (
        <>
            <div className="container-fluid">
                <HeaderComponent
                    title={"Reporte por encuesta"}
                />
                <div className="row mt-5">
                    <div className="col-sm-12 col-md-4 col-xl-4">
                        <ReportsGetSurvey surveyId={(surveyId)=>setSurveyId(surveyId)} surveyName={surveyName=>setSurveyName(surveyName)} agentId={agentId=>setAgentId(agentId)} />
                        <ReportsDateSelector visible={surveyId !== ""} start={start=>setStartDate(start)} end={end=>setEndDate(end)} />
                        {surveyId !== "" && (
                            <div>
                                {session.user.role === "ROLE_ADMIN" && <ReportsDownload surveyId={surveyId} surveyName={surveyName} startDate={startDate} endDate={endDate} />}
                            </div>
                        )}
                    </div>
                    <div className="col-sm-12 col-md-4 col-xl-8 mt-5 mt-md-0">
                        {surveyId === "" ? <ReportsEmpty /> : (
                            <div>
                                <ReportsGraphs surveyId={surveyId} agentId={agentId} startDate={startDate} endDate={endDate} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}