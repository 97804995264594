import React, {useRef, useState} from "react";
import {DivDrag, Span} from "./styles";
import UploadImage from "../../assets/svg/upload.svg";

export const DragAndDropComponent = ({handle, files}) => {
    const dropRef = useRef()
    const inputRef = useRef()
    const [dragging, setDragging] = useState(false)
    const [counter, setCounter] = useState(0)

    const handleEnter = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setCounter(counter + 1)
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            setDragging(true)
        }
    }

    const handleOver = (e) => {
        e.preventDefault()
        e.stopPropagation()
    }

    const handleLeave = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setCounter(counter - 1)
        if (counter === 0) {
            setDragging(false)
        }
    }

    const handleDrop = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setDragging(false)
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            handle(e.dataTransfer.files)
            // e.dataTransfer.clearData()
            setCounter(0)
        }
    }

    return (
        <DivDrag
            ref={dropRef}
            onDragEnter={handleEnter}
            onDragOver={handleOver}
            onDragLeave={handleLeave}
            onDrop={handleDrop}
            onClick={()=>{inputRef.current.click()}}
        >

            <input
                style={{ display: "none" }}
                ref={inputRef}
                onChange={(e) => handle(e.target.files)}
                type="file"
            />

            <div className="row">
                <div className="col-4">
                    <img src={UploadImage} alt="upload" className="img-fluid"/>
                </div>
                <div className="col-8">
                    <div style={{height: "100%", display:"table"}}>
                        {dragging || files.length===0 ?
                            <Span>Suelta el archivo aquí</Span>
                            :
                            <Span>{files[0]?.name !=="" ? files[0]?.name : "Arrasta tu archivo aquí"}</Span>
                        }
                    </div>
                </div>
            </div>


        </DivDrag>
    )
}