import React, {useContext, useEffect, useState} from "react"
import {useHistory, useParams} from "react-router"

import {capitalize} from "../../functions"
import {SessionContext} from "../../providers/SessionContext"
import {AlertContext} from "../../providers/AlertContext"
import {useLocalStorage} from "../../hooks/useLocalStorage"

import {HeaderComponent} from "../../components/Header"
import {BoxPhoneComponent} from "../../components/Survey/box_phone"
import {ButtonComponent} from "../../components/Survey/button"
import {BoxComponent} from "../../components/Box"
import {BoxSaludo} from "../../components/Survey/styles"
import {LoadingComponent} from "../../components/Loading"

import Logo from "../../assets/svg/logo/logo.svg"
import ErrorImg from "../../assets/svg/error.svg"
import {Axios} from "../../config";
import {OriginalRegisterRowModal} from "../../modals/OriginalRegisterRow";
import {RateRegisterRowModal} from "../../modals/RateRegisterRow";
import {EditRegisterRowModal} from "../../modals/EditRegisterRow";
import {RegisterAddressModal} from "../../modals/RegisterAddress";

export const SurveyPageComponent = () => {

    const {id} = useParams()
    const history = useHistory()
    const {session} = useContext(SessionContext)
    const {notificationAlert} = useContext(AlertContext)
    const [loading, setLoading] = useState(true)

    const [register, setRegister] = useLocalStorage("register", "")
    const [, setFarewellMessage] = useLocalStorage("farewell_message", "")
    const [, setAuxMessage] = useLocalStorage("auxiliary_message", "")
    const [outOfRegisters, setOutOfRegisters] = useState(false)


    const [tries, setTries] = useState("...")
    const [clientName, setClientName] = useState("Cliente")
    const [clientPhone, setClientPhone] = useState("5555555555")
    const [surveyName, setSurveyName] = useState("Nombre de la encuesta")
    const [surveyScript, setSurveyScript] = useState("Saludo de la encuesta")
    const [brandName, setBrandName] = useState("Marca")
    const [brandPhoto, setBrandPhoto] = useState(Logo)

    const [modalPostpone, setModalPostpone] = useState(false)
    const [modalDetails, setModalDetails] = useState(false)
    const [modalEditRegister, setModalEditRegister] = useState(false)
    const [modalRegisterAddress, setModalRegisterAddress] = useState(false)

    useEffect(() => {

        if(id) Axios.get("/surveys/" + id).then((response) => {
            if (response.status === 200) {
                setSurveyName(response.data.survey.name)
                setSurveyScript(response.data.survey.script)
                setFarewellMessage(response.data.survey.farewell_message)
                setAuxMessage(response.data.survey.auxiliary_message)
                setBrandName(response.data.brand.name)
                setBrandPhoto(response.data.brand.brand_logo)
            }
        })

        if (register === "") {
            const data = new FormData()
            data.append("survey_id", id)
            data.append("user_id", session.user.id)

            Axios.post("/surveys/register-rows", data)
                .then((response) => {
                    if (response.status === 200) {
                        setRegister(response.data)
                        setTries(response.data.tries)
                        buildParams(response.data)
                    }
                }).catch(() => {
                notificationAlert("Atención", "No se encontraron registros, contactar al administrador.", () => {
                    setLoading(false)
                    setOutOfRegisters(true)
                })
            })
        } else {
            buildParams(register)
        }

        //eslint-disable-next-line
    }, [id, session])

    const buildParams = (reg) => {
        const nombre = reg?.data?.nombre ?? ""
        const paterno = reg?.data?.paterno ?? ""
        const materno = reg?.data?.materno ?? ""
        setClientName(capitalize(nombre) + " " + capitalize(paterno) + " " + capitalize(materno))
        setClientPhone(reg?.data?.telefono)
        setTries(reg?.tries)
        setLoading(false)
    }

    const handleStart = () => {
        if (!loading) {
            // window.location.href = "/survey/" + id + "/1"
            history.push("/survey/" + id + "/0")
        }
    }


    if (loading) {
        return (
            <div className="container-fluid">
                <HeaderComponent
                    title={surveyName}
                />
                <LoadingComponent/>
            </div>
        )
    }


    return (
        <div className="container-fluid">
            <HeaderComponent
                title={surveyName}
            />
            {outOfRegisters ? <div className="container mt-5">
                <div className="mt-5 d-flex justify-content-center h-100">
                    <img src={ErrorImg} alt="Sin registros" className="img-fluid"/>
                </div>
            </div> : (
                <div className="container">
                    <div className="row mt-5">

                        <div className="col-md-3">
                            <div className="d-flex justify-content-start">
                                <BoxPhoneComponent>{clientPhone}</BoxPhoneComponent>
                            </div>
                        </div>

                        <div className="col-md-6 d-flex align-items-center mt-3 mt-sm-0">
                            <h1 onClick={() => setModalDetails(true)} style={{cursor: "pointer", textAlign:"center"}}>
                                <button className="btn-circle"><i className="fas fa-info"/></button>
                                {clientName}
                            </h1>
                        </div>

                        <div className="col-md-3  d-flex align-items-center mt-3 mt-sm-0">
                            <div className="d-flex justify-content-center">
                                <h1 style={{"textAlign": "center"}}>Intentos: <span
                                    style={{"color": "var(--primary-orange)"}}>{tries}</span></h1>
                            </div>
                        </div>



                    </div>
                    <div className="mt-5"/>
                    <div className="row mt-5">
                        <div className="col-md-3">
                            <div className="d-flex justify-content-center">
                                <ButtonComponent icon="fas fa-play" text="Iniciar" onClick={handleStart}/>
                            </div>
                        </div>
                        <div className="col-md-3 mt-3 mt-sm-0">
                            <div className="d-flex justify-content-center">
                                <ButtonComponent icon="fas fa-pen" text="Editar"
                                                 onClick={() => setModalEditRegister(true)}/>
                            </div>
                        </div>
                        <div className="col-md-3 mt-3 mt-sm-0">
                            <div className="d-flex justify-content-center">
                                <ButtonComponent icon="fas fa-forward" text="Calificar"
                                                 onClick={() => setModalPostpone(true)}/>
                            </div>
                        </div>
                        <div className="col-md-3 mt-3 mt-sm-0">
                            <div className="d-flex justify-content-center">
                                <ButtonComponent icon="fas fa-map" text="Agregar dirección"
                                                 onClick={() => setModalRegisterAddress(true)}/>
                            </div>
                        </div>
                    </div>
                    <div className="mt-5"/>
                    <div className="row mt-5">
                        <div className="col-md-6">
                            <h1>Saludo</h1>
                            <div className="mt-5"/>
                            <div className="d-flex justify-content-center">
                                <BoxSaludo>{surveyScript}</BoxSaludo>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <h1>Marca</h1>
                            <div className="mt-5"/>
                            <div className="d-flex justify-content-center">
                                <BoxComponent link={"/survey/" + id} image={brandPhoto} title={brandName}/>
                            </div>
                        </div>
                    </div>
                </div>

            )}

            <OriginalRegisterRowModal register={register} showed={modalDetails} onClose={() => setModalDetails(false)}/>
            <RateRegisterRowModal showed={modalPostpone} onClose={() => setModalPostpone(false)}/>
            <EditRegisterRowModal register={register} showed={modalEditRegister} onClose={() => setModalEditRegister(false)}/>
            <RegisterAddressModal show={modalRegisterAddress} onClose={()=>setModalRegisterAddress(false)} idRegisterRow={register?.id} />
        </div>
    )
}