import React, {useEffect, useState} from "react"
import {Axios} from "../../config"

import defaultImage from "../../assets/svg/logo/logo.svg"
import {LoadingComponent} from "../Loading";

export const SurveysDiagramComponent = ({idSurvey}) => {

    const [loading, setLoading] = useState(true)
    const [surveyDiagram, setSurveyDiagram] = useState(defaultImage)

    useEffect(() => {
        const data =  new FormData()
        data.append("survey_id", idSurvey)
        if (idSurvey) Axios.post("/surveys/svg", data).then((response) => {
                setSurveyDiagram(response.data.image)
            }).finally(()=>setLoading(false))
    }, [idSurvey])

    if(loading)
    {
        return <div className="container mt-5"><LoadingComponent /></div>
    }

    return (
        <div className="mt-3 p-4">
            <div className="d-flex justify-content-center">
                <img src={surveyDiagram} alt="Diseño" className="img-fluid" style={{borderRadius: "25px"}}/>
            </div>
        </div>
    )
}
