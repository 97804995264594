import styled from "styled-components";

export const Container = styled.div`
    a {
      text-decoration: none;
      font-family: "Poppins", sans-serif;
      font-size: 22px;
      color: var(--tbase-blue);
      font-weight: bold;


      :hover, :focus, :active {
        color: var(--tbase-orange);
      }

      @media screen and (max-width: 768px) {
        margin-bottom: 1.5rem;
      }
    }
  
    .box {
      background-color: white;
      border-radius: 5px;
      padding: 35px 15px;
      max-width: 80%;
      -webkit-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.15);
      box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.15);
      border: 2px solid white;
      min-height: 280px;
      min-width: 350px;
      position: relative;

      :hover {
        border: 2px solid var(--tbase-orange);
      }
      
      img {
        max-width: 100%;
        max-height: 180px;
        vertical-align: center;
        object-fit: cover;
      }
      
    }
  
    span {
      padding-top: 15px;
      position: absolute;
      bottom: 15px;
    }
  
`