import React, {useContext, useEffect, useState} from "react"

import {Axios} from "../config"
import {LoadingComponent} from "../components/Loading"
import {avatarColumn, findInArray} from "../functions"
import {DatatableComponent} from "../components/Datatable"
import {SwitchOptions} from "../components/SwitchOptions"
import {AlertContext} from "../providers/AlertContext"

export const AssignUsersTable = ({idSurvey}) => {

    const { successAlert, errorAlert} = useContext(AlertContext)
    const [loading, setLoading] = useState(true)
    const [users, setUsers] = useState([])
    const [values, setValues] = useState([])
    const [clickProtection, setClickProtection] = useState(false)

    const handleUpdateUser = (id, index) => {

        if(clickProtection) return
        setClickProtection(true)

        const _values = [...values]
        const endpoint = _values[index] ? "/surveys/users/detach-user" : "/surveys/users/attach-user"
        const message =  _values[index] ? "Se desactivo el usuario seleccionado" : "Se asigno el usuario seleccionado"
        _values[index] = !_values[index]

        const data = new FormData()
        data.append("user_id", id)
        data.append("survey_id", idSurvey)

        Axios.post(endpoint, data).then((response)=>{
            successAlert("Correcto", message)
            setValues(_values)
        }).catch(()=>{
            errorAlert("Error", "No se pudo actualizar el usuario")
        }).finally(()=>setClickProtection(false))
    }

    const switchButtonColumn = (value, tableMeta, updateValue) => {
        return (
            <SwitchOptions id="us" option="Activo" state={values[tableMeta.rowIndex]} onChange={()=>handleUpdateUser(value, tableMeta.rowIndex)} />
        )
    }

    const columns = [
        {
            label: "Usuario",
            name: "profile_picture",
            options:{
                customBodyRender: avatarColumn,
                filter: false
            }
        },
        {
            label: "Nombre",
            name: "name"
        },
        {
            label: "Apellidos",
            name: "lastname"
        },
        {
            label: "Acción",
            name: "id",
            options: {
                customBodyRender: switchButtonColumn,
                filter: false
            }
        },
    ]

    useEffect(() => {
        Axios.get("/users").then((response) => {

            const users = response.data.filter((user)=>{return user.role !== "ROLE_ADMIN"})
            const _values = []
            users.forEach((user, i)=>{
                const surveys = user.surveys
                _values[i] = findInArray(surveys, "id", idSurvey)
            })

            setUsers(users)
            setValues(_values)
        }).finally(() => {
            setLoading(false)
        })
        // eslint-disable-next-line
    }, [])

    if (loading) {
        return <LoadingComponent/>
    }

    return (
        <div className="container mt-5 p-3" style={{backgroundColor: "white", borderRadius: "25px"}}>
            <DatatableComponent columns={columns} data={users} />
        </div>
    )
}