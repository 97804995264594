import React, {useEffect, useState} from "react"
import {FormElements} from "../Form/styles"

export const QuestionMultiAnswerComponent = ({catalogValues, currentCatalog = [""]}) => {

    const [number, setNumber] = useState(()=>{
        return currentCatalog.length > 0 ? currentCatalog.length : 1
    })

    const [values, setValues] = useState([])

    const updateFieldChanged = (index, value) => {
        let newArr = [...values]
        newArr[index] = value
        setValues(newArr)
    }

    const deleteInput = position => {
        const _values = [...values]
        delete _values[position]
        setValues(_values)
    }

    const addInput = () => {
        for(let i = 0; i<number; i++)
        {
            setValues([...values, ""])
        }
    }

    useEffect(()=>{
        addInput()
        // eslint-disable-next-line
    },[number])

    useEffect(()=>{
        catalogValues(values)
        // eslint-disable-next-line
    },[values])

    useEffect(()=>{
        setValues(currentCatalog)
        // eslint-disable-next-line
    },[])

    return (
        <FormElements>
            {values.map((value, index)=> {
                return (typeof value !== "undefined") ?
                     (
                         <div key={index}>
                        <div className="row mt-3">
                            <div className="col-10 col-sm-11">
                                <input type="text"
                                       className="form-control-lg w-100"
                                       value={values[index]}
                                       onChange={(e)=>updateFieldChanged(index, e.target.value)}/>

                            </div>
                            <div className="col-1 col-sm-1">
                                <i onClick={() => deleteInput(index)} className="fas fa-trash icon-delete"/>

                            </div>
                        </div>

                        </div>
                    )
                : ""
            })}
            <div className="row mt-2">
                <div className="col-12 d-flex justify-content-center">
                    <button className="btn btn-primary " onClick={() => setNumber(number + 1)}><i
                        className="fas fa-plus"/> Agregar opción</button>
                </div>
            </div>
        </FormElements>
    )
}