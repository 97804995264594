import React, {useEffect, useState} from "react";
import ReactDragListView from "react-drag-listview";

export const TypeQuestion6 = ({data, onChange}) => {

    const [elements, setElements]  = useState(data)

    const dragProps = {
        onDragEnd(fromIndex, toIndex) {
            const data = [...elements];
            const item = data.splice(fromIndex, 1)[0];
            data.splice(toIndex, 0, item);
            setElements(data);
        },
        nodeSelector: 'li',
        handleSelector: 'span'
    };

    useEffect(()=>{
        onChange(JSON.stringify(elements))
    // eslint-disable-next-line
    },[elements])

    return (
        <div className="budget-area">
            <div className="drag-list">
                <ReactDragListView {...dragProps}>
                <ul>
                    {elements.map( (element, index) => (
                        <li key={index} data-position={index+1} className={"bg-white w-100 mt-1"}> <span><i className="fas fa-bars" /></span> {element}</li>
                    ))}
                </ul>
                </ReactDragListView>
            </div>
        </div>
    )
}