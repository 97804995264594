import React, {useEffect, useState} from "react"
import Select from "react-select"

import {customSelectStyles} from "../../config"

export const EventCatalogComponent = ({catalogType, catalog, setCatalog, options}) => {

    const [catalogRender, setCatalogRender] = useState([])

    const CatalogType0 = [
        {label: "Si", value: 0},
        {label: "No", value: 1},
        {label: "Cualquier respuesta", value: -1},
    ]

    const CatalogType1 = [
        {label: "Cualquier respuesta", value: -1},
        {label: "1", value: 0},
        {label: "2", value: 1},
        {label: "3", value: 2},
        {label: "4", value: 3},
        {label: "5", value: 4},
        {label: "6", value: 5},
        {label: "7", value: 6},
        {label: "8", value: 7},
        {label: "9", value: 8},
        {label: "10", value: 9},
    ]

    const CatalogType2 = [
        {label: "Cualquier respuesta", value: -1},
    ]

    const CatalogType5 = [
        {label: "Totalmente desacuerdo", value: 0},
        {label: "En desacuerdo", value: 1},
        {label: "Ni en desacuerdo ni acuerdo", value: 2},
        {label: "De acuerdo", value: 3},
        {label: "Totalmente de acuerdo", value: 4},
        {label: "Cualquier respuesta", value: -1},
    ]

    useEffect(() => {
        switch (catalogType) {
            case 0:
                setCatalogRender(CatalogType0)
                break;
            case 1:
                setCatalogRender(CatalogType1)
                break;
            case 2:
                setCatalogRender(CatalogType2)
                break;
            case 3:
                setCatalogRender(options)
                break;
            case 4:
                setCatalogRender(options)
                break;
            case 5:
                setCatalogRender(CatalogType5)
                break;
            default:
                setCatalogRender([])
                break;
        }
        // eslint-disable-next-line
    }, [catalogType, options])


    return (
            <Select
                styles={customSelectStyles}
                options={catalogRender}
                value={catalog}
                placeholder="Seleccionar respuesta..."
                onChange={e => setCatalog(e)}
            />
    )
}
