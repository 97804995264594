import React, {useEffect, useRef, useState} from "react";
import {FormElements} from "../Form/styles"

export const TypeQuestion4 = ({data, onChange}) => {

    const ref = useRef(null)
    const [input, setInput] = useState("")

    const [actives, setActives] = useState(()=>{
        const len = data.length + 1
        const arr = []
        for(let i=0; i< len; i++) {arr.push(false)}
        return arr
    })

    const handleActives = position => {
        const copy = [...actives]
        copy[position] = !copy[position]
        setActives(copy)
    }

    const translateActives = () => {
        const arr = [...actives]
        const result = []
        arr.forEach((active,index)=>{
            if(active) result.push(data[index]??"Otro")
        })
        result.push(input)
        return result
    }

    useEffect(()=>{
        if(actives[data.length]) {
            ref.current.focus()
        }
        onChange(JSON.stringify(translateActives()))
    // eslint-disable-next-line
    },[actives, input])

    return (
        <div className="budget-area">
            <div className="opti-list">
                <ul>

                    {data.map( (element, index) => (
                        <li key={index} onClick={()=>handleActives(index)}  className={actives[index]?"bg-white w-100 mt-1 active":"bg-white w-100 mt-1"}> <input type="checkbox" value={index} />{element}</li>
                    ))}

                    <div className="row">
                        <div className="col-6">
                            <li onClick={()=>handleActives(data.length)}   className={actives[data.length]?"bg-white w-100 mt-1 active":"bg-white w-100 mt-1"}><input type="checkbox" name="otra" value="otro" />Otro</li>
                        </div>
                        <div className="col-6">
                            <FormElements>
                                <input value={input} onChange={(e)=>setInput(e.target.value)} ref={ref} type="text" className="form-control-lg mt-1 w-100" style={{border: actives[data.length]? "2px solid #e64302" : "2px solid #ededed", "height": "70px"}}  />
                            </FormElements>
                        </div>
                    </div>

                </ul>
            </div>
        </div>
    )
}