import ReactLoading from "react-loading";
import React from "react";

export const LoadingComponent = ({type= "spin", color = "#FF4900", height = "300px", width = "300px"}) => {
    return (
        <div className="container mt-5">
            <div className="mt-5 d-flex justify-content-center">
                <ReactLoading type={type} color={color} height={height} width={width}/>
            </div>
        </div>
    )
}