import React, {useContext, useEffect, useState} from "react";
import {Link} from "react-router-dom";

import {Container} from "./styles";
import {SessionContext} from "../../providers/SessionContext";

import IconLogo from "../../assets/svg/logo/icon.svg";
import IconHome from "../../assets/svg/menu/home.svg";
import IconReportes from "../../assets/svg/menu/reports.svg";
import IconUsuarios from "../../assets/svg/menu/users.svg";
import IconCampanias from "../../assets/svg/menu/campaign.svg";
import IconCerrarSesion from "../../assets/svg/menu/logout.svg";

const adminMenu = (
    <>
        <li>
            <Link to="/reports-and-statics">
                <img src={IconReportes} alt="Reportes"/>
                <span>Reportes</span>
            </Link>
        </li>
        <li>
            <Link to="/users">
                <img src={IconUsuarios} alt="Usuarios"/>
                <span>Usuarios</span>
            </Link>
        </li>
        <li>
            <Link to="/brands">
                <img src={IconCampanias} alt="Campañas"/>
                <span>Campañas</span>
            </Link>
        </li>
    </>
)

const supervisorMenu = (
    <>
        <li>
            <Link to="/reports-and-statics">
                <img src={IconReportes} alt="Reportes"/>
                <span>Estadísticas</span>
            </Link>
        </li>
    </>
)

export const MenuComponent = () => {

    const [menu, setMenu] = useState()
    const { session } = useContext(SessionContext)

    useEffect(()=>{
        if(session.user.role === 'ROLE_ADMIN'){
            setMenu(adminMenu)
        }
        if(session.user.role === 'ROLE_SUPERVISOR'){
            setMenu(supervisorMenu)
        }
    },[session.user.role])

    return (
        <Container>
            <nav>
                <ul>
                    <li className="logo">
                        <img src={IconLogo} alt="Logo T&BASE"/>
                    </li>
                    <li>
                        <Link to="/">
                            <img src={IconHome} alt="Home"/>
                            <span>Inicio</span>
                        </Link>
                    </li>
                    {menu}
                    <li>
                        <Link to="/exit">
                            <img src={IconCerrarSesion} alt="Cerrar sesión"/>
                            <span>Cerrar sesión</span>
                        </Link>
                    </li>
                </ul>
            </nav>
        </Container>
    )
}