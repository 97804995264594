import axios from "axios"
const sessionStorage = window.localStorage.getItem("session")
const session = !(sessionStorage === null || sessionStorage==="null") ? JSON.parse(sessionStorage) : {token: null}
const token = session.token

// axios config
const Axios = axios.create({
    baseURL: process.env.REACT_APP_BE + "/api"
})
Axios.defaults.headers.common['Authorization'] = typeof token === "string" ? `Bearer ${token}` : null
Axios.defaults.headers.common['Accept'] = 'application/json'
Axios.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded'
Axios.defaults.headers.delete['Content-Type'] = 'application/x-www-form-urlencoded'
Axios.defaults.headers.post['Content-Type'] = 'multipart/form-data'
Axios.defaults.headers.put['Content-Type'] = 'application/json'
export {Axios}


// react-select config
export const customSelectStyles = {
    option: (provided, state) => ({
        ...provided,
        background: state.isSelected ? '#FF7247' : state.isFocused ? '#f3f3f3' : 'white',
    }),
    control: (provided, state) => ({
        ...provided,
        border: state.isHover ? '3px solid #FF7247' : "2px solid #ff4900",
        boxShadow: '0 0 0 1px #d7b287',
        borderColor: state.isFocused ? '#FF7247' : state.isHover ? '#ff4900' : 'white'
    }),
    dropdownIndicator: (provided, state) => ({
        ...provided,
        color: '#FF7247',
    }),
    indicatorSeparator: (provided, state) => ({
        ...provided,
        background: 'none',
    }),
}