import React from "react";
import {useHistory} from "react-router";

import {Container} from "./styles";
import {getDateFormatted} from "../../functions";

export const CampaignBoxComponent = ({id, name, start_at, end_at}) => {

    const history = useHistory()

    return (
            <Container onClick={()=>history.push("/campaign/"+id)}>
                <div className="row">
                    <div className="col-12">
                        <h4 title={name}>{name}</h4>
                        <p className="details">
                            <span>Inicio:</span> {getDateFormatted(start_at)} <br/>
                            <span>Fin:</span> {getDateFormatted(end_at)}
                        </p>
                        <p className="icon">
                            <i className="fas fa-phone" />
                        </p>
                    </div>
                </div>
            </Container>
    )
}