import React, {useContext} from "react";
import {SessionContext} from "../../providers/SessionContext";
import {Container} from "./styles";


export const HeaderComponent = ({title}) => {

    const {session} = useContext(SessionContext)

    return (
        <Container className="container-fluid">
            <div className="row">
                <div className="col-md-8">
                    <h1>{title}</h1>
                </div>
                <div className="col-md-4 d-none d-md-block">
                    {session.user.role !== "ROLE_VISITOR" &&
                    <div className="row">
                        <div className="col-sm-12 col-md-8 col-xl-6">
                            <h3>Bienvenido</h3>
                            <p>{session?.user.name}</p>
                        </div>
                        <div className="col-sm-12 col-md-4 col-xl-6">
                            <div className="d-flex justify-content-md-center justify-content-xl-start">
                                <img src={session.user.profile_picture} alt="Avatar User" className="img-fluid"/>
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </div>
            <hr/>
        </Container>
    )
}