import styled from "styled-components";

export const Button = styled.button`
  border: none;
  border-radius: 20px;
  background: var(--tbase-orange);
  background: linear-gradient(180deg, rgba(255, 73, 0, 1) 0%, rgba(255, 128, 0, 1) 100%);
  color: white;
  font-size: 22px;
  font-weight: bold;
  padding: 1rem 1.5rem;
  min-width: 250px;
  max-width: 250px;
  height: 200px;

  i {
    font-size: 50px;
    padding-bottom: 2rem;
  }
`

export const BoxList = styled.div`
  border: 2px solid var(--tbase-orange);
  padding: 1rem;
  border-radius: 5px;
  background-color: white;
  width: 100%;
  height: 350px;
  overflow-y: scroll;
  position: relative;
  
  ul {
    list-style-type: none;
    padding: 0;
  }
  
  li {
    margin-bottom: 1rem;
    color: var(--tbase-blueblue);
    font-weight: bold;
    font-size: 18px;
    width: 100%;
  }
  
  i {
    color: var(--tbase-orange);
    cursor: pointer;
    font-size: 20px;
    margin-right: 6px;
  }
`

export const ButtonAssign = styled.button`
    left: 0;
    width: 70%;
    margin-top: 1rem;
    margin-left: 15%;
    height: 60px;
    border: none;
    padding: 1rem;
    background: var(--tbase-orange);
    background: linear-gradient(90deg, rgba(255, 73, 0, 1) 0%, rgba(255, 128, 0, 1) 100%);
    color: white;
    font-size: 20px;
    border-radius: 10px;
`

export const BoxSaludo = styled.div`
      width: 100%;
      min-height: 350px;
      background-color: white;
      border: 1px solid #c2c2c2;
      border-radius: 20px;
      padding: 1rem;
      font-size: 18px;
      font-weight: bold;
    `

export const Box = styled.div`
      border: 2px solid var(--tbase-orange);
      background-color: white;
      font-weight: bold;
      color: var(--tbase-blue);
      font-size: 30px;
      padding: 1rem;
      border-radius: 15px;
      
      i {
        color: var(--tbase-orange);
        padding-left: 10px;
        cursor: pointer;
      }
    `