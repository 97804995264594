import React, {useState} from "react"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import {Modal} from "../components/Modal"

export const SelectDateReportModal = ({show, onClose, start, end}) => {

    const [startAt, setStartAt] = useState(new Date())
    const [endAt, setEndAt] = useState(new Date())

    const handleSubmit = () => {
        onClose()
        start(startAt)
        end(endAt)
    }

    return (
        <Modal show={show} onClose={handleSubmit}>
            <div>
                <h3>Seleccionar rango de fechas</h3>
                <p>Precisar de los siguientes valores para configurar el reporte.</p>

                <div className="form-group mt-3">
                    <label>Fecha de inicio:</label> <br/>
                    <DatePicker className="w-100" selected={startAt} onChange={(date) => setStartAt(date)} locale="es"/>
                </div>

                <div className="form-group mt-3">
                    <label>Fecha de límite:</label> <br/>
                    <DatePicker className="w-100" selected={endAt} onChange={(date) => setEndAt(date)} locale="es"/>
                </div>

                <div className="form-group mt-5">
                    <div className="d-flex justify-content-center">
                        <button className="btn btn-primary" onClick={handleSubmit}><i
                            className="fas fa-arrow-right"/> Continuar
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}