import styled from "styled-components"

export const Container = styled.div`
  image {
    min-width: 5rem;
  }

  nav {
    position: fixed;
    background-color: white;
    transition: width 200ms ease;
    width: 14rem;
    -webkit-border-top-right-radius: 30px;
    -moz-border-radius-topright: 30px;
    border-top-right-radius: 30px;
    z-index: 999;
    box-shadow: 10px 0px 31px -17px rgba(207, 207, 207, 0.16);
    -webkit-box-shadow: 10px 0px 31px -17px rgba(207, 207, 207, 0.16);
    -moz-box-shadow: 10px 0px 31px -17px rgba(207, 207, 207, 0.16);

    @media only screen and (max-width: 768px) {
      -webkit-border-top-right-radius: 0;
      -moz-border-radius-topright: 0;
      border-top-right-radius: 0;
      bottom: 0;
      border-top: 2px solid rgb(207,207,207);
      width: 100vw;
      height: 5rem;
    }

  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;

    & :last-child {
      margin-top: auto;
    }

    @media only screen and (max-width: 768px) {
      flex-direction: row;
      height: 100%;
    }

  }

  .logo {
    margin-bottom: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    text-align: center !important;
    width: 100%;
    top: 0;

    img {
      margin: 0 auto;
    }

    @media only screen and (max-width: 768px) {
      display: none;
    }

    & :hover {
      border-radius: 0;
      background: none;
    }

  }

  li {
    width: 100%;

    a {
      display: flex;
      align-items: center;
      height: 4.5rem;
      color: #61656b;
      font-weight: bold;
      text-decoration: none;
      transition: var(--transition-speed);

      :hover {
        margin: 0 0.5rem;
        color: var(--tbase-blue);
      }

      img {
        min-width: 1.5rem;
        margin: 0 1.5rem;
      }

      @media only screen and (max-width: 768px) {
        justify-content: center;
      }
    }
  }

  span {
    margin-left: 0;
    margin-top: 0 !important;
    font-size: 1rem;
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
`
