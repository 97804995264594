import React, {useState} from "react"
import PasswordIcon from "../../assets/svg/password.svg"
import {TextAreaContainer} from "./styles";

export const InputNormal = ({name, type, placeholder, className, value, onChange, ...props}) => {
    return (
        <div className={className}>
            <div className="input-group mb-2">
                <input name={name} type={type} className="form-control" placeholder={placeholder} value={value}
                       onChange={onChange} {...props}/>
            </div>
        </div>
    )
}

export const TextArea = ({name, placeholder, className, value, onChange}) => {
    return (
        <TextAreaContainer className={className}>
            <div className="input-group mb-2">
                <textarea name={name} className="form-control" placeholder={placeholder} value={value}
                          onChange={onChange}/>
            </div>
        </TextAreaContainer>
    )
}

export const InputIcon = ({name, type, icon, placeholder, className, value, onChange}) => {
    return (
        <div className={className}>
            <div className="input-group mb-2">
                <div className="input-group-prepend">
                    <div className="input-group-text">
                        <div className="d-flex justify-content-center w-100">
                            <img src={icon} alt="Icon" className="img-fluid"/>
                        </div>
                    </div>
                </div>
                <input name={name} type={type} className="form-control bl-none" placeholder={placeholder} value={value}
                       onChange={onChange}/>
            </div>
        </div>
    )
}

export const InputPassword = ({className, icon = PasswordIcon, value, onChange}) => {

    const [type, setType] = useState("password");

    const switchElementType = () => {
        type === "password" ? setType("text") : setType("password")
    }

    const CLASS_EYE_SPAN = "fa fa-fw toggle-password";

    return (
        <div className={className}>
            <div className="input-group mb-2">
                <div className="input-group-prepend">
                    <div className="input-group-text border-radius-right-0">
                        <div className="d-flex justify-content-center w-100 py-1">
                            <img src={icon} alt="Icon" className="img-fluid"/>
                        </div>
                    </div>
                </div>
                <input type={type} className="form-control bl-none br-none h-input-fix" value={value}
                       onChange={onChange} autoComplete="new-password"/>
                <div className="input-group-append">
                    <div className="input-group-text border-radius-left-0 bw-left">
                        <span onClick={switchElementType}
                              className={type === "password" ? CLASS_EYE_SPAN + " fa-eye" : CLASS_EYE_SPAN + " fa-eye-slash"}/>
                    </div>
                </div>
            </div>
        </div>
    )
}
