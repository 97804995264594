import React, {useContext, useEffect, useState} from "react"
import {useParams} from "react-router-dom"
import {useHistory} from "react-router"

import {HeaderComponent} from "../../../components/Header"
import {LoadingComponent} from "../../../components/Loading"
import {SurveyMenu} from "../../../components/Survey/menu"
import {SurveyQuestion} from "../../../components/Survey/question"
import {useLocalStorage} from "../../../hooks/useLocalStorage"
import {evalEvents, isNumeric, translateQuestion} from "../../../functions"
import {Axios} from "../../../config"
import {AlertContext} from "../../../providers/AlertContext"
import {SessionContext} from "../../../providers/SessionContext"

export const SurveyQuestionPageComponent = () => {

    const {survey_id, question_position} = useParams()
    const history = useHistory()
    const {errorAlert} = useContext(AlertContext)
    const {session} = useContext(SessionContext)

    const [register, setRegister] = useLocalStorage("register", "")
    const [farewellMessage] = useLocalStorage("farewell_message", "")
    const [auxMessage] = useLocalStorage("auxiliary_message", "")

    const [loading, setLoading] = useState(question_position !== "final")
    const [answer, setAnswer] = useState("")
    const [currentQuestion, setCurrentQuestion] = useState({size: 0})
    const [bindButton, setBindButton] = useState(false)


    const back = () => {
        history.goBack()
    }

    const next = () => {

        if (bindButton) return
        setBindButton(true)

        const events = currentQuestion.events

        let next = isNumeric(question_position) ? parseInt(question_position) + 1 : null

        const nextLink = evalEvents(events, survey_id, answer, question_position, next, currentQuestion.size)

        if (question_position !== "final") {

            setLoading(true)

            const data = new FormData()
            data.append("answer", answer)
            data.append("position", parseInt(question_position))
            data.append("question_id", currentQuestion.id)
            data.append("user_id", session.id)
            data.append("register_row_id", register.id)

            Axios.post("/answers", data)
                .then(response => {
                    if (response.status === 201) {
                        //window.location.href = nextLink
                        setBindButton(false)
                        history.push(nextLink)
                    }
                })
                .catch(() => {
                    errorAlert("Error", "No se puedo guardar la encuesta, contactar al administrador.", () => {
                        setBindButton(false)
                    })
                })

            return
        }

        setRegister("")
        setTimeout(() => {
            window.location.href = nextLink
        }, 500)
    }

    const handleOnChange = value => {
        setAnswer(value)
    }

    useEffect(() => {

        if (question_position !== "final") Axios.get(`/questions/${survey_id}/${question_position}`)
            .then((response) => {
                const _question = translateQuestion(response.data, register)
                setCurrentQuestion(_question)
                window.scroll(0, 0)
            })

        const data = {
            status: question_position === "final" ? "Encuestado" : "En curso",
            position: question_position === "final" ? currentQuestion.size : question_position,
            postponed_at: null,
        }

        Axios.put("/register-rows/" + register.id, JSON.stringify(data)).then(() => {})
            .catch(() => {
                errorAlert("Error", "No se puedo continuar con la encuesta, contactar al administrador.")
            })
            .finally(() => {
                setLoading(false)
            })
        // eslint-disable-next-line
    }, [question_position])

    if (loading) {
        return <LoadingComponent/>
    }

    return (
        <div className="container-fluid">
            <HeaderComponent
                title={question_position !== "final" ? "Pregunta " + (Number(question_position) + 1) + " de " + currentQuestion?.size : "Despedida"}
            />
            <div className="container">
                <SurveyMenu surveyId={survey_id} auxMessage={auxMessage} questionPosition={question_position}
                            register={register}/>
                <SurveyQuestion questionPosition={question_position} currentQuestion={currentQuestion}
                                farewellMessage={farewellMessage} handleOnChange={handleOnChange}/>
                <div className="row mt-3">
                    <div className="col-md-6 d-flex justify-content-end">
                        {question_position !== "1" && (
                            <button className="btn btn-secondary" onClick={back}>
                                <i className="fas fa-arrow-left"/>
                                Pregunta anterior
                            </button>
                        )}
                    </div>
                    <div className="col-md-6 d-flex justify-content-start">
                        <button className="btn btn-secondary" onClick={next}>
                            <i className="fas fa-arrow-right"/>
                            {question_position === "final" ? "Terminar encuesta" : "Siguiente Pregunta"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}