import styled from "styled-components"

export const Container = styled.div`

  background-color: white;
  border: 2px solid white;
  width: 100%;
  min-height: 150px;
  border-radius: 25px;
  box-shadow: 0 0 12px 12px rgba(217, 217, 217, 0.1);

  .banner {
    position: relative;
    width: 100%;
    height: 100px;
    border-radius: 25px;
    background: rgb(0, 48, 95);
    background: -moz-linear-gradient(90deg, rgba(0, 48, 95, 1) 0%, rgba(255, 114, 71, 1) 50%, rgba(0, 48, 95, 1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(0, 48, 95, 1) 0%, rgba(255, 114, 71, 1) 50%, rgba(0, 48, 95, 1) 100%);
    background: linear-gradient(90deg, rgba(0, 48, 95, 1) 0%, rgba(255, 114, 71, 1) 50%, rgba(0, 48, 95, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00305f",endColorstr="#00305f",GradientType=1);
    z-index: 0;

    .img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 25px;
      z-index: 1;
      position: relative;
    }

    .circle {
      width: 200px;
      height: 200px;
      border-radius: 50%;
      box-shadow: 0 0 12px 12px rgba(217, 217, 217, 0.2);
      margin-left: auto;
      margin-right: auto;
      margin-top: -200px;
      background-color: white;
      z-index: 2;
      position: relative;

      .logo {
        width: 90%;
        height: 90%;
        border-radius: 50%;
        object-fit: contain;
      }

    }

  }

  .menu {
    margin-top: 20px;

    ul {
      list-style: none;

      li {
        display: inline;
        margin-right: 5px;
        color: var(--tbase-blue);
        padding: 5px 10px;
        cursor: pointer;
        font-weight: bold;

        @media screen and (max-width: 768px) {
          display: block;

          i.fa-bezier-curve {
            font-size: 0.6rem;
          }

        }

        :hover, &.active {
          color: var(--tbase-orange);
        }
      }
    }
  }

  .circle {
    background: #f7ead8;
    border-radius: 25px;
    color: var(--primary-orange);
    font-size: 19px;
    height: 35px;
    min-width: 35px;
    padding: 4px 3px 0 3px;
    text-align: center;
  }

  .questions-list {
    background-color: white;
    color: var(--dark-blue);
    font-weight: bold;
    font-size: 0.8rem;
    padding: 0.5rem 1rem;
    border-bottom: 1px dotted #dadada;

    :hover {
      transition: var(--transition-speed);
      background-color: #f7ead8;
    }
  }

  .questions-events {
    background-color: white;
    color: var(--dark-blue);
    font-weight: bold;
    font-size: 0.8rem;
    padding: 0.5rem 1rem;
    border-bottom: 2px dotted #cccccc;

    .circle {
      cursor: pointer;
    }

  }

`

