import React, {useState, useContext, useRef, useEffect} from "react"
import {useHistory} from "react-router"

import {FormElements} from "../Form/styles"
import {SessionContext} from "../../providers/SessionContext"
import {AlertContext} from "../../providers/AlertContext"

import TBaseLogo from "../../assets/svg/logo/logo.svg"
import {LoadingComponent} from "../Loading";

export const LoginComponent = () => {

    const history = useHistory()
    const {requestSession, session} = useContext(SessionContext)
    const {errorAlert} = useContext(AlertContext)
    const inputEmail = useRef(false)

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [loading, setLoading] = useState(false)


    const handleSubmit = () => {

        setLoading(true)

        if (email.trim() === "" || password.trim() === "") {
            errorAlert("Error", "El correo o contraseña no puede estar vacio.",  handleReset)
            return
        }

        requestSession(email, password).then((response) => {
            if (typeof response === 'number' || typeof response === 'undefined')
            {
                errorAlert("Error", "Usuario o contraseña incorrectos.", handleReset)
                return
            }
            window.location.reload()
        });
    }

    const handleReset = () => {
        setLoading(false)
        setEmail("")
        setPassword("")
        inputEmail.current.focus()
    }

    useEffect(()=>{
        if (session.user.role === 'ROLE_ADMIN') {
            history.push("/dashboard/admin")
        }
        if (session.user.role === 'ROLE_SUPERVISOR') {
            history.push("/dashboard/agent")
        }
        if (session.user.role === 'ROLE_AGENT') {
            history.push("/dashboard/agent")
        }
    },[session.user.role, history])

    return (
        <>
            {loading ? (
                <LoadingComponent />
            ): (
                <div className="container">
                    <FormElements>
                        <div className="d-flex justify-content-sm-center justify-content-md-center justify-content-lg-end">
                            <img src={TBaseLogo} alt="T&Base Logo" className="img-fluid"/>
                        </div>
                        <h1 className="d-flex justify-content-end mt-5">Ingresa tú usuario y contraseña</h1>
                        <div className="d-flex justify-content-end mt-5">
                            <div className="row w-75">
                                <div className="col-sm-12 col-md-3 col-xl-3 text-sm-start text-md-center text-xl-end">
                                    <label htmlFor="mail">Correo:</label>
                                </div>
                                <div className="col-sm-12 col-md-9 col-xl-9">
                                    <input value={email} onChange={e => setEmail(e.target.value)} ref={inputEmail} type="text" id="mail"/>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end mt-5">
                            <div className="row w-75">
                                <div className="col-sm-12 col-md-3 col-xl-3 text-sm-start text-md-center text-xl-end">
                                    <label htmlFor="mail">Contraseña:</label>
                                </div>
                                <div className="col-sm-12 col-md-9 col-xl-9">
                                    <input value={password} onChange={e => setPassword(e.target.value)} type="password"
                                           id="password"/>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end mt-5">
                            <button onClick={handleSubmit} className="btn btn-primary">Ingresar <i
                                className="fas fa-arrow-right"/></button>
                        </div>
                    </FormElements>
                </div>
            )}
        </>
    )
}