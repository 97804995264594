import React from "react";
import {Button} from "./styles";

export const ButtonComponent = ({icon, text, onClick}) => {
    return (
        <Button onClick={onClick}>
            <i className={icon} /> <br/>
            {text}
        </Button>
    )
}