import React, {useContext, useState} from "react"

import {AlertContext} from "../providers/AlertContext"
import {Axios} from "../config"
import {Modal} from "../components/Modal"
import {LoadingComponent} from "../components/Loading"
import {InputNormal} from "../components/Form"
import {DragAndDropComponent} from "../components/DragAndDrop"

export const RegisterBrandModal = ({show, onClose}) => {

    const {successAlert, errorAlert} = useContext(AlertContext)
    const [loading, setLoading] = useState(false)

    const [name, setName] = useState("")
    const [brandLogo, setBrandLogo] = useState([{name: ''}])


    const handleSubmit = () => {

        setLoading(true)
        const data = new FormData()
        data.append('name', name)
        if (brandLogo[0].name !== '') {
            data.append('brand_logo', brandLogo[0])
        }

        Axios.post("/brands", data)
            .then((response) => {
                if (response.status === 201) {
                    setLoading(false)
                    successAlert("Correcto", "Marca creada.", ()=>{window.location.reload()})
                }
            }).catch((e) => {
            setLoading(false)
            errorAlert("Error", "No se pudo crear la marca.")
        })

    }


    return (
        <Modal show={show} onClose={onClose}>
            {loading ? (
                <LoadingComponent />
            ) : (
                <div>
                    <h3>Registrar nueva marca</h3>
                    <p>El logotipo de la marca es obligatorio.</p>

                    <div className="form-group mt-3">
                        <label>Nombre:</label>
                        <InputNormal type="text" className="w-100" value={name}
                                     onChange={(e) => setName(e.target.value)}/>
                    </div>

                    <div className="form-group mt-3">
                        <label htmlFor="">Logo de la marca:</label>
                        <DragAndDropComponent handle={file => setBrandLogo(file)} files={brandLogo}/>
                    </div>

                    <div className="form-group mt-5">
                        <div className="d-flex justify-content-center">
                            <button className="btn-blue" onClick={handleSubmit}><i
                                className="fas fa-user-plus"/> Crear marca
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </Modal>
    )
}