import React, {useContext, useEffect, useState} from "react";

import {HeaderComponent} from "../components/Header";
import {BoxComponent} from "../components/Box";
import {TextButton} from "../components/TextButton";
import {SessionContext} from "../providers/SessionContext";
import {LoadingComponent} from "../components/Loading";
import {Axios} from "../config";
import {setComponentsPerCols} from "../functions";
import {RegisterBrandModal} from "../modals/RegisterBrand";

export const BrandsPageComponent = () =>{

    const {session} = useContext(SessionContext)

    const [loading, setLoading] = useState(true)
    const [brands, setBrands] = useState([])
    const [modal, setModal] = useState(false)

    useEffect(()=>{
        Axios.get("/brands").then((response) => {
            if(response.status === 200)
            {
                setLoading(false)
                setBrands(setComponentsPerCols(response.data, 3))
            }
        })
    },[session.token])


    return (
        <div className="container-fluid">
            <HeaderComponent
                title={"Campañas"}
            />
            <div className="pt-5"/>
            <div className="container">

                <div className="row">
                    <div className="col-md-6">
                        <TextButton text="Registrar marca" icon="fas fa-plus-circle" onClick={()=>setModal(true)} />
                    </div>
                </div>

                {loading && (
                    <LoadingComponent />
                )}

                {brands.map((row, index)=> (
                    <div key={index} className="row pt-5">
                        {row.map((brand, i) =>(
                            <div key={i} className="col-md-4 mt-3 mt-sm-0">
                                <div className="d-flex justify-content-center">
                                    <BoxComponent
                                        link={"/campaigns/" + brand.id}
                                        image={brand.brand_logo}
                                        title={brand.name}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>

            <RegisterBrandModal show={modal} onClose={()=>setModal(false)}/>
        </div>
    )

}