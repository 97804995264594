import React, {useContext, useEffect, useState} from "react"
import {useHistory} from "react-router"
import Select from "react-select"

import {HeaderComponent} from "../../components/Header"
import {SessionContext} from "../../providers/SessionContext"
import {useLocalStorage} from "../../hooks/useLocalStorage"
import {greetByTime} from "../../functions"
import {Axios, customSelectStyles} from "../../config"

export const AgentPageComponent = () => {

    const history = useHistory()
    const {session} = useContext(SessionContext)
    const [ ,setRegister ] = useLocalStorage("register", "")
    const [fatalError, setFatalError] = useState(false)
    const [surveysCatalog, setSurveysCatalog] = useState([])
    const [selected, setSelected] = useState(null)
    const [greet] = useState(greetByTime)

    useEffect(() => {
        setRegister("")
        Axios.get( "/surveys/by-user/" + session.user.id)
            .then((response) => {
            const surveys = response.data
            if (surveys.length === 0) {
                setFatalError(true)
            } else {
                const _surveys = surveys.map((survey) => {return {label: survey.name, value: survey.id}})
                setSurveysCatalog(_surveys)
            }
        })
        //eslint-disable-next-line
    }, [session.user.id])

    return (
        <div className="container-fluid">
            <HeaderComponent
                title={greet}
            />
            <div className="container">

                {fatalError && <div className="container-fluid p-0 mt-5">
                        <div className="alert alert-info">
                            No tienes encuestas asignadas.
                        </div>
                    </div>}

                <div className="row mt-5">
                    <div className="col-6">
                            <Select
                                styles={customSelectStyles}
                                placeholder="Selecciona tu encuesta"
                                options={surveysCatalog}
                                value={selected}
                                onChange={e=>setSelected(e)}
                            />

                    </div>
                    <div className="col-6">
                        <div className="d-flex justify-content-center">
                            { selected !==null && <button className="btn-blue" onClick={() => history.push("/survey/" + selected.value)}> <i
                                className="fas fa-play-circle"/> Iniciar encuesta</button>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}