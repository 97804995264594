import React, {useState} from "react"

import {HeaderComponent} from "../../components/Header"
import {ReportsEmpty} from "../../components/Reports/empty"
import {ReportsDateSelector} from "../../components/Reports/date_selector"
import {ReportsGetAgent} from "../../components/Reports/get_agents";
import {ReportsUserStatics} from "../../components/Reports/user_statics";

export const StaticsByAgentPageComponent = () => {

    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const [agent, setAgent] = useState(null)

    return (
        <>
            <div className="container-fluid">
                <HeaderComponent
                    title={"Estadísticas del agente"}
                />
                <div className="row mt-5">
                    <div className="col-sm-12 col-md-4 col-xl-4">
                        <ReportsGetAgent agent={agent} setAgent={setAgent} />
                        <ReportsDateSelector visible={agent} start={start=>setStartDate(start)} end={end=>setEndDate(end)} />
                    </div>
                    <div className="col-sm-12 col-md-4 col-xl-8 mt-3 mt-md-0">
                        { !agent ? <ReportsEmpty /> : (
                            <div>
                                <ReportsUserStatics agentId={agent.value} startDate={startDate} endDate={endDate} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}