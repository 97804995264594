import React from "react"

import {Container} from "./styles"

import bg from "../../assets/images/banner/header-1.png"
import defaultLogo from "../../assets/svg/logo/logo.svg"

export const SurveysHeaderBox = ({selectedPage, onClick, logo = defaultLogo}) => {
    return (
        <Container className="container pt-3 ps-3 pe-3 pb-1">
            <div className="banner mt-2">
                <img src={bg} alt="" className="img" />
                <div className="circle d-flex flex-row justify-content-center align-items-center">
                    <img src={logo} alt="" className="logo" />
                </div>
            </div>
            <div className="menu">
                <ul>
                    <li onClick={()=>onClick(0)} className={selectedPage === 0 ? "active" : ""}><i className="fas fa-question"/> Preguntas</li>
                    <li onClick={()=>onClick(1)} className={selectedPage === 1 ? "active" : ""}><i className="fas fa-angle-double-right" /> Eventos</li>
                    <li onClick={()=>onClick(2)} className={selectedPage === 2 ? "active" : ""}><i className="fas fa-bezier-curve" /> Diagrama</li>
                    <li onClick={()=>onClick(3)} className={selectedPage === 3 ? "active" : ""}><i className="fas fa-child" /> Agentes asignados</li>
                    <li onClick={()=>onClick(4)} className={selectedPage === 4 ? "active" : ""}><i className="fas fa-database"/> Base de datos</li>
                    <li onClick={()=>onClick(5)} className={selectedPage === 5 ? "active" : ""}><i className="fas fa-book" /> Registros</li>
                </ul>
            </div>
        </Container>
    )
}