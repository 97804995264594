import React from "react";

export const greetByTime = () => {
    const date = new Date();
    if (date.getHours() >= 0 && date.getHours() < 12) {
        return "Buenos días ☀"
    }

    if (date.getHours() >= 12 && date.getHours() < 18) {
        return "Buenas tardes ☘"
    }

    if (date.getHours() >= 18 && date.getHours() < 24) {
        return "Buenas noches 🌜"
    }
}

export const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    s = s.toLowerCase()
    return s.charAt(0).toUpperCase() + s.slice(1)
}

export const copy2Clipboard = (areaRef, el) => {
    let currentNode = areaRef.current
    const selection = window.getSelection()
    const range = document.createRange()
    range.selectNodeContents(currentNode)
    selection.removeAllRanges()
    selection.addRange(range)
    document.execCommand('copy')
    selection.removeAllRanges()
    return el + " copiado"
}

export const getDateFormatted = (str) => {
    const date = new Date(str)
    const options = {day: 'numeric', month: 'long', year: 'numeric'}
    return  date.toLocaleDateString('es-ES', options)
}

export const getDateFormattedFull = (str) => {
    const date = new Date(str)
    const options = {day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric'}
    return  date.toLocaleDateString('es-ES', options)
}

export const dateToTimestamp = ( date ) => {
    return '@' + Math.round(date.getTime() / 1000)
}

let autoComplete;

export const loadScript = (url, callback) => {
    let script = document.createElement("script");
    script.type = "text/javascript";

    if (script.readyState) {
        script.onreadystatechange = function() {
            if (script.readyState === "loaded" || script.readyState === "complete") {
                script.onreadystatechange = null;
                callback();
            }
        };
    } else {
        script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
};

export function handleScriptLoad(updateQuery, autoCompleteRef) {
    autoComplete = new window.google.maps.places.Autocomplete(
        autoCompleteRef.current,
        { types: [], componentRestrictions: { } }
    );
    autoComplete.setFields(["address_components", "formatted_address"]);
    autoComplete.addListener("place_changed", () =>
        handlePlaceSelect(updateQuery)
    );
}

async function handlePlaceSelect(updateQuery) {
    const addressObject = autoComplete.getPlace();
    const query = addressObject.formatted_address;
    updateQuery(query);
    console.log(addressObject);
}

export const removeHTMLTags = (str) => {
    const regex = /(<([^>]+)>)/ig;
    return  str.replace(regex, '')
        .replace("&hellip;","...")
        .replace("&nbsp;", "");
}

export const searchInArrayOfObjects = (array, key, expected, text) => {
    const value =  array.find( el => el[key] === text );
    if(value!==undefined){
        return  value[expected];
    }
    return "";
}

export const returnInArrayOfObjects = (array, key, expected) => {
    const value =  array.find( el => el[key] === parseInt(expected));
    if(value!==undefined){
        return  value;
    }
    return {};
}

export const findInArray = (array, key, expected) => {
    const value = array.find(el => el[key] === expected)
    return !(value === null || value === undefined)
}


export const setComponentsPerCols = (elements, cols) => {
    let content = [];
    let tmp = [];
    elements.forEach((element, index)=>{
        if (index%cols===0) {
            tmp=[];
            tmp.push(element);
            if(tmp.length>0)
                content.push(tmp);
        }else{
            tmp.push(element);
        }
    });
    return content;
}

export const validateEmail = (email) => {
    const regEx = /^([\da-z_.-]+)@([\da-z.-]+)\.([a-z.]{2,6})$/
    return regEx.test(email);
}

export const isNumeric =(num) => {
    num = "" + num;
    return !isNaN(num) && !isNaN(parseFloat(num));
}

export const translateQuestion = (question, register) => {
    if(typeof register === "undefined" || register === "" || register === null) return question

    const headers4replace = Object.keys(register.data).map( (el) => {return ":" + el + ":"})
    const registerValues = Object.values(register.data)

    headers4replace.forEach( (header, index) => {
        question.name = question.name.replace(header, capitalize(registerValues[index]))
    })
    return question
}

/**
 * Event type 0: End Survey
 * Event type 1: jump to [position]
 */
export const evalEvents = (events, survey_id, answer, current, next, size) => {

    const survey_url = "/survey/" + survey_id

    if (current === "final" || next === null) return survey_url

    if (Number(current) === size -1) return survey_url + "/final"

    if (events.length === 0) return survey_url + "/" + next

    let link

    for (let i = 0; i < events.length; i++) {

        if (isNumeric(answer)) {
            answer = answer.toString()
        }

        if(events[i].answer === "-1" && events[i].type === 0)
        {
            link = survey_url + "/final"
            break
        }

        if(events[i].answer === "-1" && events[i].type === 1)
        {
            link = survey_url + "/" + (parseInt(events[i].jump_to.position) - 1)
            break
        }

        if (answer !== events[i].answer) {
            link = survey_url + "/" + next

            if (i < events.length) continue

            break
        }

        if (events[i].type === 0) {
            link = survey_url + "/final"
            break
        }

        if (events[i].type === 1) {
            link = survey_url + "/" + (parseInt(events[i].jump_to.position) - 1)
            break
        }
    }
    return link;
}

export const clearQuestionOptions = catalog => {
    if(!Array.isArray(catalog))
        return JSON.stringify([])
    const cleared = []
    catalog.forEach((option) => {
        if(!(option === null || typeof option === "undefined"))
        {
            cleared.push(option)
        }
    })
    return JSON.stringify(cleared)
}

export const reloadWithParams = params => {
    window.location.href = window.location.protocol
        + "//"
        + window.location.host
        + window.location.pathname
        + '?'
        + params.join('&')
}

export const avatarColumn = (value, tableMeta, updateValue) => {
    return (
        <div className="d-flex justify-content-center" style={{maxWidth: "100px"}}>
            <div><img src={value} alt="avatar" className="img-fluid" style={{borderRadius: "50%", height: "80px", width: "80px"}} /></div>
        </div>
    )
}

export const dateColumn = (value, tableMeta, updateValue) => {
    if(value === null) return "Fecha no disponible"
    return getDateFormatted(value)
}

export const fullDateColumn = (value, tableMeta, updateValue) => {
    return getDateFormattedFull(value)
}