import React from "react"
import {Box} from "./styles";

export const ReportsBox = ({children, title, icon}) => {
    return (
        <div className="d-flex justify-content-center">
            <Box>
                <h4>{title}</h4>
                <p>{children}</p>
                <i className={icon}/>
            </Box>
        </div>
    )
}