import React, {useEffect, useState} from "react"
import Select from "react-select"

import {Axios, customSelectStyles} from "../../config"
import {Container} from "./styles"

export const ReportsGetAgent = ({agent, setAgent}) => {

    const [agents, setAgents] = useState([])

    useEffect(() => {
        Axios.get("/users").then((response) => {
            if (response.status === 200) {
                const users = response.data
                const _agents = [{label: "Todos", value: ""}]
                users.forEach(user => {
                    if(user.role !== "ROLE_ADMIN")
                        _agents.push({label: user.name + " " + user.lastname, value: user.id})
                })
                setAgents(_agents)
            }
        })
        //eslint-disable-next-line
    }, [])

    return (
        <Container>
            <div className="row mt-3">
                <label className="mb-2">Agente:</label><br/>
                <Select
                    styles={customSelectStyles}
                    options={agents}
                    placeholder={"Seleccionar agente..."}
                    value={agent}
                    onChange={e => setAgent(e)}
                />
                <div className="row mt-3"/>
            </div>
        </Container>
    )
}