import React, {useContext, useEffect, useState} from "react"

import {HeaderComponent} from "../components/Header"
import {UsersCard} from "../components/Users/card"
import {TextButton} from "../components/TextButton"
import {SwitchOptions} from "../components/SwitchOptions"
import {LoadingComponent} from "../components/Loading"
import {setComponentsPerCols} from "../functions"
import {Axios} from "../config"
import {SessionContext} from "../providers/SessionContext"
import {RegisterUserModal} from "../modals/RegisterUser"
import {EditUserModal} from "../modals/EditUser"

export const UsersPageComponent = () => {

    const { session } = useContext(SessionContext)

    const [loading, setLoading] = useState(true)
    const [administrativeUser, setAdministrativeUser] = useState(false)
    const [agents, setAgents] = useState([])
    const [administrative, setAdministrative] = useState([])
    const [usersCurrent, setUsersCurrent] = useState([])
    const [userEdit, setUserEdit] = useState({
        userId: "",
        userName: "",
        userLastname: "",
        userEmail: "",
        userRole: "ROLE_ADMIN",
        userActive: ""
    })
    const [modalRegister, setModalRegister] = useState(false)
    const [modalEdit, setModalEdit] = useState(false)

    useEffect(()=>{
        Axios.get("/users")
            .then((response)=>{
            if(response.status === 200)
            {
                setLoading(false)
                const _usersAgents = []
                const _usersAdministrative = []

                response.data.forEach(user => {
                    user.role === "ROLE_AGENT" ? _usersAgents.push(user) : _usersAdministrative.push(user)
                })

                setAgents(_usersAgents)
                setAdministrative(_usersAdministrative)
                setUsersCurrent(setComponentsPerCols(_usersAgents, 3))
            }
        })
    },[])

    useEffect(()=>{
        if(administrativeUser){
            setUsersCurrent(setComponentsPerCols([...administrative], 3))
            return
        }
        setUsersCurrent(setComponentsPerCols([...agents], 3))
        //eslint-disable-next-line
    },[administrativeUser])

    const handleEditUser = data => {
        setUserEdit({
            userId: data.id,
            userName: data.name,
            userLastname: data.lastname,
            userEmail: data.email,
            userRole: data.role,
            userActive: data.active,
        })
        setModalEdit(true)
    }

    return (
        <div className="container-fluid">
            <HeaderComponent
                title={"Usuarios"}
            />
            <div className="pt-5"/>
            <div className="container">

                <div className="row">
                    <div className="col-md-6">
                        <TextButton text="Registrar nuevo usuario" icon="fas fa-user-plus" onClick={()=>setModalRegister(true)} />
                    </div>

                    {session.user.role === "ROLE_ADMIN" && (
                        <div className="col-md-6 mt-5 mt-sm-0">
                            <SwitchOptions id="oa" option="Supervisores y administradores" state={administrativeUser} onChange={()=>setAdministrativeUser(!administrativeUser)} />
                        </div>
                    )}
                </div>

                {loading && (
                    <LoadingComponent />
                )}

                {usersCurrent.map((row, index)=> (
                    <div key={index} className="row pt-5">
                        {row.map((user, i) =>(
                            <div key={"user" + i} className="col-md-4 mt-3 mt-sm-0">
                                <UsersCard {...user} onEdit={(data)=>handleEditUser(data)} />
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            <RegisterUserModal show={modalRegister} onClose={()=>setModalRegister(false)} />
            <EditUserModal show={modalEdit} onClose={()=>setModalEdit(false)} {...userEdit}/>
        </div>
    )
}