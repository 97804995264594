import React, {useEffect, useState} from "react";

export const TypeQuestion1 = ({onChange}) => {

    const [active, setActive] = useState(0)

    useEffect(()=>{
        onChange((active-1).toString())
    },[active, onChange])

    return (
        <div className="budget-area">
            <div className="opti-list">
                <ul className="d-md-flex">
                    <li onClick={()=>setActive(1)} className={active===1?"bg-white active":"bg-white"}><input type="checkbox" name="code_opti1" value="1" />1</li>
                    <li onClick={()=>setActive(2)} className={active===2?"bg-white active":"bg-white"}><input type="checkbox" name="code_opti2" value="2" />2</li>
                    <li onClick={()=>setActive(3)} className={active===3?"bg-white active":"bg-white"}><input type="checkbox" name="code_opti3" value="3" />3</li>
                    <li onClick={()=>setActive(4)} className={active===4?"bg-white active":"bg-white"}><input type="checkbox" name="code_opti4" value="4" />4</li>
                    <li onClick={()=>setActive(5)} className={active===5?"bg-white active":"bg-white"}><input type="checkbox" name="code_opti5" value="5" />5</li>
                    <li onClick={()=>setActive(6)} className={active===6?"bg-white active":"bg-white"}><input type="checkbox" name="code_opti6" value="6" />6</li>
                    <li onClick={()=>setActive(7)} className={active===7?"bg-white active":"bg-white"}><input type="checkbox" name="code_opti7" value="7" />7</li>
                    <li onClick={()=>setActive(8)} className={active===8?"bg-white active":"bg-white"}><input type="checkbox" name="code_opti8" value="8" />8</li>
                    <li onClick={()=>setActive(9)} className={active===9?"bg-white active":"bg-white"}><input type="checkbox" name="code_opti9" value="9" />9</li>
                    <li onClick={()=>setActive(10)} className={active===10?"bg-white active":"bg-white"}><input type="checkbox" name="code_opti10" value="10" />10</li>
                </ul>
            </div>
        </div>
    )
}