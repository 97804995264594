import React, {useState} from "react"
import {OriginalRegisterRowModal} from "../../modals/OriginalRegisterRow"
import {PostponeRegisterRowModal} from "../../modals/PostponeRegisterRow"
import {EditRegisterRowModal} from "../../modals/EditRegisterRow"
import {RegisterAddressModal} from "../../modals/RegisterAddress";

export const SurveyMenu = ({surveyId, auxMessage, questionPosition, register}) => {

    const [modalOriginalData, setModalOriginalData] = useState(false)
    const [modalPostpone, setModalPostpone] = useState(false)
    const [modalEditRegister, setModalEditRegister] = useState(false)
    const [modalRegisterAddress, setModalRegisterAddress] = useState(false)

    return (
        <>
            <div className="container">
                <div className="row mt-3">
                    <div className="col-md-3">
                        {questionPosition !== "final" && (
                            <button className="btn-text" style={{marginRight: "26px"}}
                                    onClick={() => setModalOriginalData(true)}>
                                <i className="fas fa-info"/>{register.data?.nombre + " " + register.data?.paterno}
                            </button>
                        )}
                    </div>
                    <div className="col-md-9 mt-3 mt-sm-0">
                        {questionPosition !== "final" && (
                            <div>
                                <div className="d-flex justify-content-end">
                                    <button className="btn-text"
                                            onClick={() => setModalPostpone(true)}>
                                        <i className="fas fa-calendar"/> Calificar llamada
                                    </button>
                                    <button className="btn-text" onClick={() => setModalEditRegister(true)}><i
                                        className="fas fa-pen"/> Editar registro
                                    </button>
                                    <button className="btn-text" onClick={() => setModalRegisterAddress(true)}><i
                                        className="fas fa-location-arrow"/> Agregar dirección
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="row mt-2"/>
            <OriginalRegisterRowModal register={register} auxMessage={auxMessage} showed={modalOriginalData} onClose={() => setModalOriginalData(false)}/>
            <PostponeRegisterRowModal questionPosition={questionPosition} survey_id={surveyId} showed={modalPostpone} onClose={() => setModalPostpone(false)}/>
            <EditRegisterRowModal register={register} showed={modalEditRegister} onClose={() => setModalEditRegister(false)}/>
            <RegisterAddressModal show={modalRegisterAddress} onClose={()=>setModalRegisterAddress(false)} idRegisterRow={register?.id} />

        </>
    )
}