import React, {useContext, useState} from "react"
import DatePicker from "react-datepicker"
import Select from "react-select"

import {AlertContext} from "../providers/AlertContext"
import {useLocalStorage} from "../hooks/useLocalStorage"
import {Modal} from "../components/Modal"
import {LoadingComponent} from "../components/Loading"
import {dateToTimestamp} from "../functions"
import {Axios, customSelectStyles} from "../config"

const catalogPostpone = [
    {label: "La persona esta ocupada", value: "La persona esta ocupada"},
    {label: "Proporciono otro teléfono", value: "Proporciono otro teléfono"},
    {label: "Se corto llamada", value: "Se corto llamada"},
    {label: "No le interesa", value: "No le interesa"}
]

export const PostponeRegisterRowModal = ({showed, onClose, questionPosition, survey_id}) => {

    const {successAlert, errorAlert} = useContext(AlertContext)
    const [register, setRegister] = useLocalStorage("register", "")
    const [loading, setLoading] = useState(false)
    const [reasonPostpone, setReasonPostpone] = useState()
    const [datePostpone, setDatePostpone] = useState(new Date())

    const handlePostpone = () => {
        setLoading(true)
        const data = {
            status: reasonPostpone.value,
            position: questionPosition,
            postponed_at: dateToTimestamp(datePostpone),
        }
        Axios.put("/register-rows/" + register.id, JSON.stringify(data))
            .then((response) => {
            if (response.status === 200)
            {
                setLoading(false)
                setRegister("")
                successAlert("Correcto", "Registro agendado con exito.", () => {
                    window.location.href = "/survey/" + survey_id
                })
            }
        }).catch(() => {
            setLoading(false)
            errorAlert("Error", "No se pudo agendar el registro.")
        })
    }

return (
    <>
        <Modal show={showed} onClose={onClose}
                        footer={<button className="btn btn-secondary" onClick={handlePostpone}><i
                            className="fas fa-calendar-day"/> Posponer
                        </button>}
        >
            {loading ? (
                <LoadingComponent/>
            ) : (
                <div>
                    <h3>Registro #{register.id}</h3>
                    <p className="mb-4">Seleccionar el motivo y la fecha/hora si aplica.</p>

                    <div className="row mt-3">
                        <div className="col-12">
                            <span><strong>Motivo:</strong></span>
                            <div className="mt-3"/>
                            <Select
                                styles={customSelectStyles}
                                options={catalogPostpone}
                                placeholder={"Seleccionar estatus..."}
                                value={reasonPostpone}
                                onChange={e => setReasonPostpone(e)}
                            />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-12 mt-3">
                            <span><strong>Fecha:</strong></span>
                            <div className="mt-3"/>
                            <DatePicker className="w-100"
                                        selected={datePostpone}
                                        onChange={(date) => setDatePostpone(date)}
                                        locale="es"
                                        dateFormat="Pp"
                                        showTimeSelect
                            />
                        </div>
                    </div>
                    <div className="row mt-5" />
                </div>)}
        </Modal>
    </>
    )
}