import React, {useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";

import {HeaderComponent} from "../../components/Header";
import {LoadingComponent} from "../../components/Loading";
import {SessionContext} from "../../providers/SessionContext";
import {SurveyBoxComponent} from "../../components/SurveyBox";
import {TextButton} from "../../components/TextButton";
import {getDateFormatted, setComponentsPerCols} from "../../functions";
import {Axios} from "../../config";
import {RegisterSurveyModal} from "../../modals/RegisterSurvey";

export const CampaignIdComponent = () => {

    const {id} = useParams()

    const { session } = useContext(SessionContext)
    const [loading, setLoading] = useState(true)
    const [surveys, setSurveys] = useState([])
    const [brand, setBrand] = useState({name: '', brand_logo: null})
    const [campaign, setCampaign] = useState({name: ''})
    const [modal, setModal] = useState(false)

    useEffect(() => {
        Axios.get("/surveys/campaign/" + id)
            .then((response) => {
            if(response.status === 200)
            {
                setLoading(false)
                setBrand(response.data.brand)
                setCampaign(response.data.campaign)
                setSurveys(setComponentsPerCols(response.data.surveys, 1))
            }
        })
    }, [session.token, id])

    if (loading) {
        return <LoadingComponent/>

    }

    return <>
        <div className="container-fluid">
            <HeaderComponent
                title={brand.name + " > " + campaign.name + " > Encuestas"}
            />
            <div className="pt-3 pt-sm-5"/>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-3">
                        <div className="d-flex justify-content-center">
                            <img src={brand.brand_logo} alt="" style={{width: "200px", objectFit: "contain"}}/>
                        </div>
                        <h5 className="mt-2 text-center">{brand.name + " > " + campaign.name}</h5>
                        <p className="text-center"><strong>Inicio:</strong> {getDateFormatted(campaign.start_at)} <br/>
                           <strong>Fin:</strong> {getDateFormatted(campaign.end_at)}
                        </p>
                    </div>
                    <div className="col-md-9 mt-3 mt-sm-0">
                        <TextButton text="Crear Encuesta" icon="fas fa-plus-circle" onClick={()=>setModal(true)} />
                        <div className="row mt-5">
                            <div className="col-md-8">
                                {surveys.map((row, index) => (
                                    <div key={"row" + index} className="row pt-3    ">
                                        {row.map((survey, i) => (
                                            <div key={"campaign" + i} className="col-md-12 mt-3 mt-sm-0">
                                                <SurveyBoxComponent
                                                    link={"/surveys/" + survey.id}
                                                    title={survey.name}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                            <div className="col-md-4" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <RegisterSurveyModal show={modal} onClose={()=>setModal(false)} campaignId={id} />
    </>
}