import React, {useContext, useEffect, useState} from "react"

import {LoadingComponent} from "../components/Loading"
import {TextButton} from "../components/TextButton"
import {DatatableComponent} from "../components/Datatable"
import {AlertContext} from "../providers/AlertContext"
import {Axios} from "../config"
import {dateColumn, reloadWithParams} from "../functions"
import {OriginalRegisterRowModal} from "../modals/OriginalRegisterRow"
import {UpdatedRegisterRowModal} from "../modals/UpdatedRegisterRow"

export const RegistersRowTable = ({idSurvey}) => {

    const { confirmationAlert, successAlert, errorAlert } = useContext(AlertContext)
    const [loading, setLoading] = useState(true)
    const [registers, setRegisters] = useState([])

    const [currentRegister, setCurrentRegister] = useState({
        id: null
    })
    const [modalOriginalData, setModalOriginalData] = useState(false)
    const [modalUpdatedData, setModalUpdatedData] = useState(false)

    const handleCleanRegisters = () => {
        confirmationAlert("Limpiar registros", "Esta opción permite re-asignar los registros " +
            "de volver a llamar para el despachador de registros automático.", ()=>{
            Axios.get(`/surveys/reset/${idSurvey}`).then((response)=>{
                successAlert("Correcto", "Operación completada", ()=>{
                    reloadWithParams(["view=registers"])
                })
            }).catch(()=>{
                errorAlert("Error", "No se pudo completar la operación.")
            })
        })
    }

    const setRegisterForModal = (type, id) => {

        /*const register = registers.filter(reg=>{return reg.id === id})
        if(register.length===0) return
        setCurrentRegister(register[0])*/

        Axios.get("/register-rows/" + id).then(res=>{
            setCurrentRegister(res.data)
        })

        if(type === "original")
        {
            setModalOriginalData(true)
            return
        }
        setModalUpdatedData(true)
    }

    const viewOriginalDataColumn = (value, tableMeta, updateValue) => {
        return (
            <div className="d-flex justify-content-center">
                <span className="circle-icon" onClick={()=>setRegisterForModal("original", value)}>
                    <i className="fas fa-eye " style={{cursor: "pointer"}} />
                </span>
            </div>
        )
    }

    const viewUpdatedDataColumn = (value, tableMeta, updateValue) => {
        return (
            <div className="d-flex justify-content-center">
                <span className="circle-icon" onClick={()=>setRegisterForModal("updated", value)}>
                    <i className="fas fa-eye " style={{cursor: "pointer"}} />
                </span>
            </div>
        )
    }

    const columns = [
        {
            label: "ID",
            name: "id",
            options: {
                filter: false
            }
        },
        {
            label: "Agente",
            name: "user_name",
        },
        {
            label: "Base",
            name: "register_name"
        },
        {
            label: "Estatus",
            name: "status"
        },
        {
            label: "Intentos",
            name: "tries"
        },
        {
            label: "Última pregunta",
            name: "position"
        },
        {
            label: "Actualizado",
            name: "updated_at",
            options: {
                customBodyRender: dateColumn,
                filter: false
            }
        },
        {
            label: "Asignado",
            name: "assigned_at",
            options: {
                customBodyRender: dateColumn,
                filter: false
            }
        },
        {
            label: "Original",
            name: "id",
            options: {
                customBodyRender: viewOriginalDataColumn,
                filter: false
            }
        },
        {
            label: "Actualizado",
            name: "id",
            options: {
                customBodyRender: viewUpdatedDataColumn,
                filter: false
            }
        },
    ]

    useEffect(()=>{
        Axios.get(`/register-rows/survey/${idSurvey}`).then((response)=>{
            setRegisters(response.data)
        }).finally(()=>setLoading(false))
        // eslint-disable-next-line
    },[])

    if (loading) {
        return <LoadingComponent/>
    }

    return (
        <>
            <div className="container-fluid mt-5 p-3" style={{backgroundColor: "white", borderRadius: "25px"}}>
                <div className="row mt-3 mb-3">
                    <div className="col-md-12 d-flex justify-content-center">
                        <TextButton text="Limpiar registros" icon="fas fa-broom" onClick={handleCleanRegisters} />
                    </div>
                </div>
                <DatatableComponent columns={columns} data={registers} />
            </div>
            <OriginalRegisterRowModal register={currentRegister} auxMessage={null} showed={modalOriginalData} onClose={() => setModalOriginalData(false)}/>
            <UpdatedRegisterRowModal register={currentRegister}  showed={modalUpdatedData} onClose={() => setModalUpdatedData(false)}/>
        </>
    )
}