import React from "react"
import ReportsPageImage from "../../assets/svg/repots-page.svg";
import {Container} from "./styles";

export const ReportsEmpty = () => {
    return (
        <Container>
            <h3 className="intro"><i className="fas fa-arrow-up d-md-none"/><i className="fas fa-arrow-left d-none d-md-inline"/> Selecciona los parámetros.</h3>
            <div className="d-flex justify-content-center">
                <img src={ReportsPageImage} alt="Reports cover" className="img-fluid"/>
            </div>
        </Container>
    )
}