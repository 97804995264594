import React from "react"
import MUIDataTable from "mui-datatables"
import {ThemeProvider} from "@mui/styles"
import {createTheme, responsiveFontSizes} from '@mui/material/styles'

let theme = createTheme();
theme = responsiveFontSizes(theme);

const options = {
    elevation: 0,
    print: false,
    selectableRows: "none",
    download: false,
    viewColumns: false
}

export const DatatableComponent = ({columns, data}) => {
    return (
        <ThemeProvider theme={theme}>
            <MUIDataTable
                title=""
                columns={columns}
                data={data}
                options={options}
            />
        </ThemeProvider>
    )
}
