import React, {useContext, useState} from "react"
import {Modal} from "../components/Modal"
import {AlertContext} from "../providers/AlertContext"
import {LoadingComponent} from "../components/Loading"
import {Axios} from "../config"
import {InputNormal, TextArea} from "../components/Form";
import {DragAndDropComponent} from "../components/DragAndDrop";
import {useHistory} from "react-router";

export const RegisterSurveyModal = ({show, onClose, campaignId}) => {

    const history = useHistory()
    const {successAlert, errorAlert} = useContext(AlertContext)
    const [loading, setLoading] = useState(false)

    const [database, setDatabase] = useState([{name: ''}])
    const [survey, setSurvey] = useState({
        name: "",
        script: "",
        farewell_message: "",
        auxiliary_message: ""
    })

    const handleSubmit = () => {

        if(survey.name === "" || database[0].name === "") return

        setLoading(true)

        const formData = new FormData();
        formData.append('name',survey.name)
        formData.append('script',survey.script)
        formData.append('farewell_message',survey.farewell_message)
        formData.append('auxiliary_message',survey.auxiliary_message)
        formData.append('campaign_id', campaignId)

        if(database[0].name!=='')
        {
            formData.append('database',database[0])
        }

        Axios.post("/surveys", formData).then(response => {
            if( response.status===201)
            {
                setLoading(false)
                successAlert("Correcto", "Encuesta creada", ()=>{
                    history.push("/surveys/" + response.data.id)
                })
            }
        }).catch(() => {
            setLoading(false)
            errorAlert("Error", "No se pudo crear la encuesta.")
        })
    }

    if(loading)
    {
        return <Modal show={show} onClose={onClose} size="lg"><LoadingComponent /></Modal>
    }

    return (
        <Modal show={show} onClose={onClose} size="lg">
            <h3>Registrar nueva encuesta</h3>
            <p>El nombre y base de datos es obligatorio.</p>

            <div className="form-group mt-3">
                <label>Nombre de la encuesta:</label>
                <InputNormal type="text" className="w-100" value={survey.name}
                             onChange={(e) => setSurvey({...survey, name: e.target.value})}/>
            </div>

            <div className="form-group mt-3">
                <label>Saludo:</label>
                <TextArea className="w-100" value={survey.script}
                             onChange={(e) => setSurvey({...survey, script: e.target.value})}/>
            </div>

            <div className="form-group mt-3">
                <label>Despedida:</label>
                <TextArea className="w-100" value={survey.farewell_message}
                             onChange={(e) => setSurvey({...survey, farewell_message: e.target.value})}/>
            </div>

            <div className="form-group mt-3">
                <label>Información Auxiliar:</label>
                <TextArea className="w-100" value={survey.auxiliary_message}
                             onChange={(e) => setSurvey({...survey, auxiliary_message: e.target.value})}/>
            </div>

            <div className="form-group mt-3">
                <label htmlFor="">Base inicial en CSV:</label>
                <DragAndDropComponent handle={file => setDatabase(file)} files={database}/>
            </div>

            <div className="form-group mt-5">
                <div className="d-flex justify-content-center">
                    <button className="btn-blue" onClick={handleSubmit}><i
                        className="fas fa-user-plus"/> Crear encuesta
                    </button>
                </div>
            </div>

        </Modal>
    )
 }
