import React, {useState} from "react"
import {Axios} from "../../config";
import {LoadingComponent} from "../Loading";

export const ReportsDownload = ({surveyId, surveyName, startDate, endDate}) => {

    const [loading, setLoading] = useState(false)

    const handleDownload = () => {
        setLoading(true)
        const data = new FormData()
        data.append("start_at",  '@' + Math.round(startDate.getTime()/1000))
        data.append("end_at",  '@' + Math.round(endDate.getTime()/1000))

        Axios.post("/surveys/download/" + surveyId, data, {
            headers: {
                "Accept": "application/xlsx"
            },
            responseType: 'blob',
        })
            .then(response => new Blob([response.data], { type: 'application/xlsx' }))
            .then(blob => {
                let url = window.URL.createObjectURL(blob)
                let a = document.createElement('a')
                a.href = url
                a.download = surveyName + ".xlsx"
                a.click()
                a.remove()
                setLoading(false)
                setTimeout(() => window.URL.revokeObjectURL(url), 100)
            })
            .catch((e) => {})
    }

    if(loading)
    {
        return <LoadingComponent />
    }

    return (
        <>
        <div className="row mt-5">
            <div className="col-12">
                <div className="d-flex justify-content-center">
                    <button className="btn-blue" onClick={handleDownload}>Descargar base <i className="fas fa-download" /></button>
                </div>
            </div>
        </div>

        </>
    )
}