import React, {useContext, useEffect} from "react";
import {SessionContext} from "../providers/SessionContext";

export const ExitPageComponent = () => {
    const {destroySession} = useContext(SessionContext)

    useEffect(()=>{
        destroySession().then(()=>{
            window.location.href = "/"
        })
    },[destroySession])

    return <></>
}