import React from "react"
import {Container} from "./styles";

export const SwitchOptions = ({id, option, state, onChange}) => {

    return (
        <Container className="form-switch">
            <input className="form-check-input" type="checkbox" id={id} checked={state} onChange={onChange}/>
            <label htmlFor={id}>{option}</label>
        </Container>
    )
}