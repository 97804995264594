import React, {useContext, useState} from "react"
import Select from "react-select"

import {Modal} from "../components/Modal"
import {TextArea} from "../components/Form"
import {Axios, customSelectStyles} from "../config"
import {QuestionMultiAnswerComponent} from "../components/Question/multianswer"
import {LoadingComponent} from "../components/Loading"
import {ButtonParam} from "../components/Question/styles"
import {AlertContext} from "../providers/AlertContext";
import {clearQuestionOptions, reloadWithParams} from "../functions";

const questionTypeCatalog = [
    {label: "Si / No", value: 0},
    {label: "Seleccione del 1 al 10", value: 1},
    {label: "Respuesta libre", value: 2},
    {label: "Catálogo Unico", value: 3},
    {label: "Catálogo múltiple", value: 4},
    {label: "Likert", value: 5},
    {label: "Ranking", value: 6},
]

export const RegisterQuestionModal = ({show, onClose, buttons, surveyId}) => {

    const { continueAlert, errorAlert } = useContext(AlertContext)
    const [loading, setLoading] = useState(false)

    const [question, setQuestion] = useState({
        name: "",
        type: null
    })
    const [catalog, setCatalog] = useState([])

    const handleSubmit = () => {

        if(question.type === null) return

        setLoading(true)

        const data = new FormData()
        data.append("name", question.name)
        data.append("type", question.type.value)
        data.append("content", clearQuestionOptions(catalog))
        data.append("survey_id", surveyId)

        Axios.post("/questions", data)
            .then((response) => {
                if(response.status===201)
                {
                    continueAlert("Pregunta agregada", "Agregar otra pregunta",
                        ()=>{
                           setQuestion({name: "", type: null})
                           setCatalog([])
                        },
                        ()=>{
                            reloadWithParams(["view=questions"])
                        })
                }
            }).catch(() => {
            errorAlert("Error", "No se pudo agregar la pregunta.")
        }).finally(()=> setLoading(false))

    }

    const addParam = param => {
        setQuestion({...question, name: question.name+" :"+param+": "})
    }

    if (loading) {
        return <Modal show={show} onClose={onClose} size="lg"><LoadingComponent/></Modal>
    }

    return (
        <Modal show={show} onClose={onClose} size="lg">
            <h3>Nueva pregunta</h3>
            <p>Crear nueva pregunta</p>

            <div className="row">
                <div className="col-md-12">
                    <div className="form-group mt-3">
                        {buttons.map((val, i) => (
                            <ButtonParam key={i} onClick={() => addParam(val)}>
                                :{val}:
                            </ButtonParam>
                        ))}
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div className="form-group mt-3">
                        <label>Pregunta:</label>
                        <TextArea className="w-100" value={question.name}
                                  onChange={(e) => setQuestion({...question, name: e.target.value})}/>
                    </div>
                    <div className="form-group mt-3">
                        <label>Tipo de respuesta:</label>
                        <Select
                            styles={customSelectStyles}
                            placeholder={"Seleccionar el tipo..."}
                            options={questionTypeCatalog}
                            value={question.type}
                            onChange={e => setQuestion({...question, type: e})}
                        />
                    </div>

                    {(question.type?.value === 3 || question.type?.value === 4 || question.type?.value === 6) && (
                        <div className="form-group mt-4 mb-3">
                            <label>Respuesta:</label>
                            <QuestionMultiAnswerComponent catalogValues={e => setCatalog(e)}/>
                        </div>
                    )}

                    <div className="form-group mt-4">
                        <div className="d-flex justify-content-center">
                            <button className="btn-blue" onClick={handleSubmit}><i
                                className="fas fa-pen"/> Agregar pregunta
                            </button>
                        </div>
                    </div>
                </div>
            </div>


        </Modal>
    )
}
