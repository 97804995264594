import React, {useContext, useEffect, useState} from "react"
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService"
import ReactLoading from "react-loading"

import {Modal} from "../components/Modal"
import {LoadingComponent} from "../components/Loading"
import {Axios} from "../config";
import {AlertContext} from "../providers/AlertContext"

export const RegisterAddressModal = ({show, onClose, idRegisterRow}) => {

    const {successAlert, errorAlert} = useContext(AlertContext)
    const [loading, setLoading] = useState(false)
    const [selected, setSelected] = useState(null)
    const [address, setAddress] = useState({
        name: "",
        address: "",
        place_id: null,
        lat: null,
        lng: null,
        url: null
    })

    const {
        placesService,
        placePredictions,
        getPlacePredictions,
        isPlacePredictionsLoading,
    } = useGoogle({
        apiKey: process.env.REACT_APP_GOOGLEMAPSKEY,
    });

    useEffect(()=>{
        if(placesService && selected)
        {
            placesService.getDetails({placeId: selected.place_id}, (place, status)=>{
                const lat = place.geometry.location.lat()
                const lng = place.geometry.location.lng()
                const url = place.url
                setAddress({...address, lat: lat, lng: lng, url: url, place_id: selected.place_id})
            })

        }
        // eslint-disable-next-line
    },[selected])

    const handleSelected = place => {
        setAddress({...address, address: place.description})
        setSelected(place)
        getPlacePredictions({input: ""})
    }

    const clear = () => {
        setLoading(false)
        setSelected(null)
        setAddress({
            name: "",
            address: "",
            place_id: null,
            lat: null,
            lng: null,
            url: null
        })
        onClose()
    }

    const handleSubmit = () => {

        if(address.name==="" || address.address==="" || selected === null) return

        setLoading(true)

        const data = new FormData()
        data.append("register_row_id", idRegisterRow)
        data.append("name", address.name)
        data.append("address", address.address)
        data.append("place_id", address.place_id)
        data.append("latitude", address.lat)
        data.append("longitude", address.lng)
        data.append("url", address.url)

        Axios.post("/addresses", data).then((response)=>{
            successAlert("Correcto", "Dirección asignada con éxito")
        }).catch(()=>{
            errorAlert("Error", "No se pudo asignar la dirección")
        }).finally(clear)
    }

    if(loading)
    {
        return <Modal show={show} onClose={onClose} size="lg"><LoadingComponent /></Modal>
    }

    return (
        <Modal show={show} onClose={onClose} size="lg">
            <h3>Guardar dirección</h3>
            <p>Escriba en el campo y seleccionar de la lista.</p>

            <div className="form-group mt-3">
                <label>Nombre:</label>
                <input type="text"
                       className="form-control"
                       placeholder="Casa, Oficina..."
                       value={address.name}
                       onChange={(e) =>
                           setAddress({...address, name:e.target.value})
                       }
                />
            </div>

            <div className="form-group mt-2">
                <label>Dirección:</label>
                <input type="text"
                       className="form-control"
                       value={address.address}
                       onChange={(evt) => {
                           getPlacePredictions({ input: evt.target.value });
                           setAddress({...address, address:evt.target.value});
                       }}
                />
            </div>

            {placePredictions.length > 0 &&
                <div
                    style={{
                        marginTop: "20px",
                        width: "100%",
                        height: "240px",
                        display: "flex",
                        flex: "1",
                        flexDirection: "column",
                        overflow: "scroll"
                    }}
                >
                    {!isPlacePredictionsLoading ? (
                        <ul>
                            {placePredictions.map((item, i)=>(
                                <li key={i} onClick={() => handleSelected(item)}>
                                    <span className="circle-icon"><i className="fas fa-location-arrow" /></span>{item.description}
                                </li>
                            ))}
                        </ul>
                    ): <div className="d-flex justify-content-center">
                        <ReactLoading type="spin" color="#FF4900" height="150px" width="150px"/>
                    </div>}
                </div>
            }


            <div className="form-group mt-3">
                <div className="d-flex justify-content-center">
                    <button className="btn-blue" onClick={handleSubmit}><i
                        className="fas fa-user-plus"/>Guardar dirección
                    </button>
                </div>
            </div>
        </Modal>
    )
}