import React, {useContext} from "react"

import {HeaderComponent} from "../../components/Header"
import {BoxComponent} from "../../components/Box"

import ReportsBySurvey from "../../assets/svg/reports-survey.svg"
import StaticsByAgent from "../../assets/svg/statics-agent.svg"
import ReportsAddresses from "../../assets/svg/reports-addresses.svg"
import {SessionContext} from "../../providers/SessionContext";

export const StaticsAndReportPageComponent = () => {

    const {session} = useContext(SessionContext)

    return (
        <>
            <div className="container-fluid">
                <HeaderComponent
                    title={"Estadísticas y reportes"}
                />

                <div className="container pt-5">

                    <div className="row mt-3">
                        <div className="col-md-4">
                            <div className="d-flex justify-content-center">
                                <BoxComponent image={ReportsBySurvey} title="Reportes de las encuestas"
                                              link="/reports-by-survey"/>
                            </div>
                        </div>
                        <div className="col-md-4 mt-3 mt-sm-0">
                            <div className="d-flex justify-content-center">
                                <BoxComponent image={StaticsByAgent} title="Estadísticas de los usuarios" link="/statics-by-agent"/>
                            </div>
                        </div>
                        {session.user.role === "ROLE_ADMIN" &&  <div className="col-md-4 mt-3 mt-sm-0">
                            <div className="d-flex justify-content-center">
                                <BoxComponent image={ReportsAddresses} title="Direcciones" link="/reports-addresses"/>
                            </div>
                        </div>}
                    </div>
                    <div className="pt-5 mt-5"/>
                </div>
            </div>
        </>
    )
}