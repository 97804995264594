import React, {useContext, useRef} from "react";
import {Box} from "./styles";
import {AlertContext} from "../../providers/AlertContext";
import {copy2Clipboard} from "../../functions";

export const BoxPhoneComponent = ({children}) => {

    const areaRef = useRef(null);
    const {infoAlert} = useContext(AlertContext)
    
    const copy = (e) => {
        const message = copy2Clipboard(areaRef, "Teléfono")
        infoAlert(message)
    }

    return (
        <Box> <span ref={areaRef}>{children}</span> <i className="fas fa-clipboard" onClick={copy}/></Box>
    )
}