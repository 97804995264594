import React, {useEffect, useState} from "react"
import CountUp from 'react-countup'
import {ReportsBox} from "./box"
import DoughnutChart from "./doughnut"
import {Axios} from "../../config"
import {dateToTimestamp} from "../../functions";

export const ReportsGraphs = ({surveyId, agentId, startDate, endDate}) => {

    const [loading, setLoading] = useState(false)

    const [total, setTotal] = useState(0)
    const [marked, setMarked] = useState(0)
    const [completed, setCompleted] = useState(0)

    const [labelsContacted, setLabelsContacted] = useState([])
    const [valuesContacted, setValuesContacted] = useState([])
    const [labelsNoContacted, setLabelsNoContacted] = useState([])
    const [valuesNoContacted, setValuesNoContacted] = useState([])
    const [labelsNoContactable, setLabelsNoContactable] = useState([])
    const [valuesNoContactable, setValuesNoContactable] = useState([])

    useEffect(()=>{
        setLoading(true)
    },[surveyId, agentId, startDate, endDate])

    useEffect(()=>{
        if(loading)
        {
            const data = new FormData()
            data.append("start_at", dateToTimestamp(startDate))
            data.append("end_at", dateToTimestamp(endDate))
            data.append("user_id", agentId)
            Axios.post("/surveys/statics/" + surveyId, data).then((response)=>{
                if(response.status === 200)
                {
                    setLoading(false)
                    setTotal(response.data.total)
                    setMarked(response.data.marked)
                    setCompleted(response.data.completed)
                    setLabelsContacted(response.data.labels_contacted)
                    setValuesContacted(response.data.values_contacted)
                    setLabelsNoContacted(response.data.labels_no_contacted)
                    setValuesNoContacted(response.data.values_no_contacted)
                    setLabelsNoContactable(response.data.labels_no_contactable)
                    setValuesNoContactable(response.data.values_no_contactable)
                }
            })
        }
        // eslint-disable-next-line
    },[loading])

    return (
        <>
            <div className="row mt-5">
                <div className="col-md-4">
                    <ReportsBox title="Registros totales" icon="fas fa-coins">
                        <CountUp end={total} />
                    </ReportsBox>
                </div>
                <div className="col-md-4 mt-5 mt-sm-0">
                    <ReportsBox title="Registros calificados" icon="fas fa-chart-pie">
                        <CountUp end={marked} />
                    </ReportsBox>
                </div>
                <div className="col-md-4 mt-5 mt-sm-0">
                    <ReportsBox title="Encuestas" icon="far fa-list-alt">
                        <CountUp end={completed} />
                    </ReportsBox>
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-md-4">
                    <DoughnutChart title="Contacto" labels={labelsContacted} values={valuesContacted} />
                </div>
                <div className="col-md-4 mt-5 mt-sm-0">
                    <DoughnutChart title="Sin contacto aún" labels={labelsNoContacted} values={valuesNoContacted} />
                </div>
                <div className="col-md-4 mt-5 mt-sm-0">
                    <DoughnutChart title="No contactable" labels={labelsNoContactable} values={valuesNoContactable} />
                </div>
            </div>
        </>
    )
}