import React, { createContext, useState } from "react"
import {useLocalStorage} from "../hooks/useLocalStorage"
import {Axios} from "../config"

import IconUserDefault from "../assets/svg/user.svg"

export const SessionContext = createContext(undefined)

export const SessionProvider = ({children}) => {

    const [sessionStorage, setSessionStorage] = useLocalStorage("session", null)
    const [session] = useState({
        user: {
            id: sessionStorage?.user?.id ?? "",
            name: sessionStorage?.user?.name ?? "",
            role: sessionStorage?.user?.role ?? "ROLE_VISITOR",
            profile_picture: sessionStorage?.user?.profile_picture ?? IconUserDefault
        },
        token: sessionStorage?.token ?? null
    });

    const requestSession = (email, password) => {
        const data = new FormData()
        data.append("email", email)
        data.append("password", password)
        return new Axios.post("/users/login", data)
            .then((response) => {
                if (response.status === 200) {
                    const session = {
                        user: response.data.user,
                        token: response.data.token
                    }
                    setSessionStorage(session)
                    return response;
                }
            }).catch(()=>{
                return 404
            })
    }

    const destroySession = () => {
        setSessionStorage(null)
        return new Axios.post("/users/exit")
            .then((response)=>{
                if (response.status === 200) {
                    return 200
                }
            }).catch(()=>{
                return 400
            })
    }

    return (
        <SessionContext.Provider value={{session, requestSession, destroySession}}>
            {React.Children.map(children, component=>{return component})}
        </SessionContext.Provider>
    )
}
