import React, {useEffect, useState} from "react"
import Select from "react-select"

import {Axios, customSelectStyles} from "../../config"
import {Container} from "./styles"

export const ReportsGetSurvey = ({surveyId, surveyName, agentId}) => {

    const [brands, setBrands] = useState([])
    const [brand, setBrand] = useState([])

    const [campaigns, setCampaigns] = useState([])
    const [campaign, setCampaign] = useState([])

    const [surveys, setSurveys] = useState([])
    const [survey, setSurvey] = useState([])

    const [agents, setAgents] = useState([])
    const [agent, setAgent] = useState([])


    useEffect(() => {
        Axios.get("/brands").then((response) => {
            if (response.status === 200) {
                setBrands(response.data.map(brand => {
                    return {label: brand.name, value: brand.id}
                }))
            }
        })
        //eslint-disable-next-line
    }, [])


    useEffect(() => {
        setCampaign([])
        setSurvey([])
        setAgent([])
        if (Array.isArray(brand)) return

        Axios.get("/campaigns?filter[brand_id]=" + brand.value).then((response) => {
            if (response.status === 200) {
                setCampaigns(response.data.map((campaign) => {
                    return {label: campaign.name, value: campaign.id}
                }))
            }
        })
        //eslint-disable-next-line
    }, [brand])

    useEffect(() => {

        setSurvey([])
        setAgent([])
        if (Array.isArray(campaign)) return

        Axios.get("/surveys?filter[campaign_id]=" + campaign.value).then((response) => {
            if (response.status === 200) {
                setSurveys(response.data.map((survey) => {
                    return {label: survey.name, value: survey.id}
                }))
            }
        })
        //eslint-disable-next-line
    }, [campaign])


    useEffect(() => {
        surveyId(survey.value ?? "")
        surveyName(surveys.map((s) => {
            return s.value === survey.value ? s.label : ""
        }).join(''))
        if (Array.isArray(survey)) return

        Axios.get("/users").then((response) => {
            if (response.status === 200) {
                const users = response.data
                const assignedAgents = [{label: "Todos", value: ""}]
                users.forEach(user => {
                    const surveys = user.surveys
                    surveys.forEach(s => {
                        if (s.id === survey.value) {
                            assignedAgents.push({label: user.name + " " + user.lastname, value: user.id})
                        }
                    })
                })
                setAgents(assignedAgents)
            }
        })

        //eslint-disable-next-line
    }, [survey])

    useEffect(() => {
        agentId(agent.value ?? "")
        // eslint-disable-next-line
    }, [agent])

    return (
        <Container>
            <label className="mb-2">Marca:</label><br/>
            <Select
                styles={customSelectStyles}
                options={brands}
                placeholder={"Seleccionar marca..."}
                value={brand}
                onChange={e => setBrand(e)}
            />

            {!Array.isArray(brand) && (
                <div className="row mt-3">
                    <label className="mb-2">Campaña:</label><br/>
                    <Select
                        styles={customSelectStyles}
                        options={campaigns}
                        placeholder={"Seleccionar campaña..."}
                        value={campaign}
                        onChange={e => setCampaign(e)}
                    />
                    <div className="row mt-3"/>
                </div>
            )}

            {!Array.isArray(campaign) && (
                <div className="row mt-3">
                    <label className="mb-2">Encuesta:</label><br/>
                    <Select
                        styles={customSelectStyles}
                        options={surveys}
                        placeholder={"Seleccionar encuesta..."}
                        value={survey}
                        onChange={e => setSurvey(e)}
                    />
                    <div className="row mt-3"/>
                </div>
            )}

            {!Array.isArray(survey) && (
                <div className="row mt-3">
                    <label className="mb-2">Agente:</label><br/>
                    <Select
                        styles={customSelectStyles}
                        options={agents}
                        placeholder={"Seleccionar agente..."}
                        value={agent}
                        onChange={e => setAgent(e)}
                    />
                    <div className="row mt-3"/>
                </div>
            )}
        </Container>
    )
}