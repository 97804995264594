import React, {useEffect, useState} from "react";

export const TypeQuestion5 = ({onChange}) => {

    const [active, setActive] = useState(-1)

    useEffect(()=>{
        onChange(active.toString())
    },[active, onChange])

    return (
        <div className="budget-area">
            <div className="opti-list">
                <ul>
                    <div className="row">
                        <div className="col">
                            <li onClick={()=>setActive(0)}   className={active===0?"bg-white w-100 h-100 active":"bg-white w-100 h-100"}><input type="checkbox" value="0" />Totalmente en desacuerdo</li>
                        </div>
                        <div className="col">
                            <li onClick={()=>setActive(1)}   className={active===1?"bg-white w-100 h-100 active":"bg-white w-100 h-100"}><input type="checkbox" value="1" />En desacuerdo</li>
                        </div>
                        <div className="col">
                            <li onClick={()=>setActive(2)}   className={active===2?"bg-white w-100 h-100 active":"bg-white w-100 h-100"}><input type="checkbox" value="2" />Ni de acuerdo ni desacuerdo</li>
                        </div>
                        <div className="col">
                            <li onClick={()=>setActive(3)}   className={active===3?"bg-white w-100 h-100 active":"bg-white w-100 h-100"}><input type="checkbox" value="3" />De acuerdo</li>
                        </div>
                        <div className="col">
                            <li onClick={()=>setActive(4)}   className={active===4?"bg-white w-100 h-100 active":"bg-white w-100 h-100"}><input type="checkbox" value="4" />Totalmente de acuerdo</li>
                        </div>
                    </div>
                </ul>
            </div>
        </div>
    )
}