import styled from "styled-components"

export const Container = styled.div`

  h3.intro {
    color: var(--tbase-blue);
    text-align: center;
    margin-bottom: 3rem;
  }
  
  label {
    color: var(--tbase-blue);
    font-weight: bold;
    font-size: 18px;
  }
  
  .p-date {
    font-weight: bold;
    background-color: white;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    margin-top: 1rem;
    border: 2px solid var(--tbase-orange);
  }

  ul {
    list-style: none;
    padding: 0;
  }

  li {
    padding: 0.4em 0 0.4em 2.5em;
    font-size: 20px;
    cursor: pointer;
    color: var(--secondary-blue);

    :hover {
      color: var(--secondary-blue);
      font-weight: bold;
    }
  }

  @keyframes slideIn {
    from {
      margin-left: 10%;
      width: 100%
    }

    to {
      margin-left: 0%;
      width: 100%;
    }
  }

  .active {
    color: var(--secondary-blue);
    font-weight: bold;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-name: slideIn;
  }

  li:before {
    content: "\\f0a4"; /* FontAwesome Unicode */
    font-family: "Font Awesome 5 Free";
    display: inline-block;
    margin-left: -1.3em; /* same as padding-left set on li */
    width: 1.3em; /* same as padding-left set on li */
    color: var(--tbase-blue);
  }
`

export const Box = styled.div`
  position: relative;
  padding: 2rem;
  background-color: white;
  border-radius: 15px;
  border: 3px solid var(--tbase-blue);
  width: 100%;
  height: 150px;
  
  h4 {
    color: var(--tbase-blue);
    font-size: 24px;
    font-weight: bold;
  }
  
  p {
    color: var(--tbase-orange);
    font-size: 36px;
    font-weight: bold;
  }
  
  i {
    position: absolute;
    bottom: 10px;
    right: 20px;
    color: rgba(255,114,71,0.2);
    font-size: 120px;
  }
  
`

export const BoxDoughnut = styled.div`
  background-color: white;
  border-radius: 15px;
  padding: 1rem;
  border: 3px solid var(--tbase-blue);

  h4 {
    margin-top: 1rem;
    color: var(--tbase-blue);
    font-size: 24px;
    font-weight: bold;
  }
`