import React, {useEffect, useState} from "react"
import {translateDayName, translateMonthName, buildWeek, buildMonth} from "./helpers"
import {Container} from "./styles"
import {SelectDateReportModal} from "../../modals/SelectDateReport";

export const ReportsDateSelector = ({visible, start, end}) => {

    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const [active, setActive] = useState(0)
    const [dateString, setDateString] = useState("")
    const [modal, setModal] = useState(false)

    useEffect(()=>{
        const now = new Date()
        switch (active) {
            case 0:
                setStartDate(now)
                setEndDate(now)
                break
            case 1:
                now.setDate(now.getDate() -1)
                setStartDate(now)
                setEndDate(now)
                break
            case 2:
                const [startCurrentWeek,endCurrentWeek] = buildWeek(now.getDay())
                setStartDate(startCurrentWeek)
                setEndDate(endCurrentWeek)
                break
            case 3:
                const [startPastWeek,endPastWeek] = buildWeek(now.getDay(), 7)
                setStartDate(startPastWeek)
                setEndDate(endPastWeek)
                break
            case 4:
                const [startCurrentMonth,endCurrentMonth] = buildMonth(now.getDate())
                setStartDate(startCurrentMonth)
                setEndDate(endCurrentMonth)
                break
            case 5:
                const [startPastMonth,endPastMonth] = buildMonth(now.getDate(),1)
                setStartDate(startPastMonth)
                setEndDate(endPastMonth)
                break
            case 6:
                const startAllSegment = new Date(0)
                setStartDate(startAllSegment)
                setEndDate(new Date())
                break
            default:
                break
        }
        // eslint-disable-next-line
    },[active])


    useEffect(()=>{

        start(startDate)
        end(endDate)

        const startDay = translateDayName(startDate.getDay())
        const startNumDay = startDate.getDate()
        const startMonth = translateMonthName(startDate.getMonth())
        const startYear = startDate.getFullYear()

        const endDay = translateDayName(endDate.getDay())
        const endNumDay = endDate.getDate()
        const endMonth = translateMonthName(endDate.getMonth())
        const endYear = endDate.getFullYear()

        switch (active) {
            case 0:
            case 1:
                setDateString(`${startDay} ${startNumDay} de ${startMonth} del ${startYear}`)
                break
            case 6:
                setDateString("Todos los registros")
                break
            default:
                setDateString(`Desde el ${startDay} ${startNumDay} de ${startMonth} del ${startYear} al ${endDay} ${endNumDay} de ${endMonth} del ${endYear} `)
                break
        }
        // eslint-disable-next-line
    },[startDate, endDate])

    const handleModal = () => {
        setActive(7)
        setModal(true)
    }

    if(!visible)
        return <></>

    return (
        <Container>
            <label>Fecha seleccionada:</label> <br/>
            <p className="p-date text-center">{dateString}</p>
            <ul>
                <li onClick={()=>setActive(0)} className={active === 0 ? "active": ""}>Hoy</li>
                <li onClick={()=>setActive(1)} className={active === 1 ? "active": ""}>Ayer</li>
                <li onClick={()=>setActive(2)} className={active === 2 ? "active": ""}>Esta semana</li>
                <li onClick={()=>setActive(3)} className={active === 3 ? "active": ""}>Semana anterior</li>
                <li onClick={()=>setActive(4)} className={active === 4 ? "active": ""}>Este més</li>
                <li onClick={()=>setActive(5)} className={active === 5 ? "active": ""}>Mes anterior</li>
                <li onClick={()=>setActive(6)} className={active === 6 ? "active": ""}>Todos los registros</li>
                <li onClick={handleModal} className={active === 7 ? "active": ""}>Rango personalizado</li>
            </ul>

            <SelectDateReportModal show={modal} onClose={()=>setModal(false)} start={start => setStartDate(start)} end={end => setEndDate(end)} />
        </Container>
    )
}