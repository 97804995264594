import React, {useContext, useState} from "react"
import Select from "react-select"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

import {AlertContext} from "../providers/AlertContext"
import {dateToTimestamp} from "../functions"
import {Axios, customSelectStyles} from "../config"
import {Modal} from "../components/Modal"
import {LoadingComponent} from "../components/Loading"
import {InputNormal} from "../components/Form"

export const RegisterCampaignModal = ({brand_id, show, onClose}) => {

    const {successAlert, errorAlert} = useContext(AlertContext)
    const [loading, setLoading] = useState(false)

    const [type, setType] = useState()
    const [name, setName] = useState("")
    const [startAt, setStartAt] = useState(new Date())
    const [endAt, setEndAt] = useState(new Date())

    const handleSubmit = () => {
        setLoading(true)
        const data = new FormData()
        data.append('brand_id', brand_id)
        data.append('name', name)
        data.append('start_at', dateToTimestamp(startAt))
        data.append('end_at', dateToTimestamp(endAt))
        Axios.post("/campaigns", data)
            .then((response) => {
                if (response.status === 201) {
                    setLoading(false)
                    successAlert("Correcto", "Camapaña creada.", ()=>{window.location.reload()})
                }
            }).catch(() => {
            setLoading(false)
            errorAlert("Error", "No se pudo crear la campaña.")
        })
    }


    return (
        <Modal show={show} onClose={onClose}>
            {loading ? (
                <LoadingComponent />
            ) : (
                <div>
                    <h3>Registrar nueva campaña</h3>
                    <p>Todos los campos son obligatorios.</p>

                    <div className="form-group mt-5">
                        <label htmlFor="">Tipo: (Proximamente WhatsApp)</label>
                        <Select
                            styles={customSelectStyles}
                            options={[
                                {label: "Telefónica", value: "Teléfonica"},
                                {label: "WhatsApp (próximamente)", value: "WhatsApp"},
                            ]}
                            placeholder={"Seleccionar tipo..."}
                            value={type}
                            onChange={e => setType(e)}
                        />
                    </div>

                    <div className="form-group mt-3">
                        <label>Nombre:</label>
                        <InputNormal type="text" className="w-100" value={name} onChange={(e) => setName(e.target.value)}/>
                    </div>

                    <div className="form-group mt-3">
                        <label>Fecha de inicio:</label> <br/>
                        <DatePicker className="w-100" selected={startAt} onChange={(date) => setStartAt(date)} locale="es" />
                    </div>

                    <div className="form-group mt-3">
                        <label>Fecha de término:</label> <br/>
                        <DatePicker className="w-100" selected={endAt} onChange={(date) => setEndAt(date)} locale="es" />
                    </div>

                    <div className="form-group mt-5">
                        <div className="d-flex justify-content-center">
                            <button className="btn-blue" onClick={handleSubmit}><i
                                className="fas fa-user-plus"/> Crear campaña
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </Modal>
    )
}