import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  border-radius: 10px;
  background: linear-gradient(140deg, var(--primary-orange) 0%, var(--tbase-orange) 100%);
  border: 3px solid var(--secondary-orange);
  color: white;
  width: 100%;
  min-height: 150px;
  cursor: pointer;
  
  h4 {
    font-weight: bold;
    padding-top: 1rem;
    padding-left: 0.7rem;
    max-height: 50px;
    overflow: hidden;
    white-space: inherit;
    text-overflow: ellipsis;
  }
  
  p.details {
    position: absolute;
    bottom: 0;
  }
  
  span {
    padding-left: 0.7rem;
    padding-bottom: 0.2rem;
    font-size: 1rem;
    font-weight: bold;
  }
  
  p.icon {
    position: absolute;
    bottom: 0;
    right: 20px;
    
    i {
      font-size: 60px;
      filter: opacity(0.3);
    }
  }
  
`

export const ButtonWhite = styled.button`
  border: none;
  border-radius: 50px;
  width: 2.2rem;
  height: 2.2rem;
  background-color: white;
  -webkit-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.15);
  box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.15);
  margin-right: 1rem;
`

export const ButtonOrange = styled.button`
  border: none;
  border-radius: 50px;
  width: 2.2rem;
  height: 2.2rem;
  background: var(--orange-b);
  background: linear-gradient(180deg, rgba(255, 73, 0, 1) 0%, rgba(255, 128, 0, 1) 100%);
  -webkit-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.15);
  box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.15);
  margin-right: 1rem;
`

export const BoxSurvey = styled.div`
  background-color: white;
  width: 99%;
  -webkit-box-shadow: 0px 0px 6px 2px rgba(0,0,0,0.15);
  box-shadow: 0px 0px 6px 2px rgba(0,0,0,0.15);
  border-radius: 10px;
  padding: 0.8rem;
  
  span {
    color: var(--dark-blue);
    font-weight: bold;
    line-height: 2rem;
  }
`

export const ButtonSurvey = styled.button`
  width: 99%;
  border-radius: 10px;
  padding: 0.8rem;
  background: var(--orange-b);
  background: linear-gradient(180deg, rgba(255, 73, 0, 1) 0%, rgba(255, 128, 0, 1) 100%);
  -webkit-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.15);
  box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.15);
  border: none;
  font-weight: bold;
  color: white;
`