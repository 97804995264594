import styled from "styled-components";

export const RadioYN = styled.div`

  label {
    display: flex;
    cursor: pointer;
    font-weight: 500;
    position: relative;
    overflow: hidden;
    margin-bottom: 0.375em;
  }

  label input {
    position: absolute;
    left: -9999px;
  }

  label input:checked + span {
    background-color: #f9e0d0;
  }

  label input:checked + span:before {
    box-shadow: inset 0 0 0 0.4375em var(--tbase-orange);
  }

  label span {
    display: flex;
    align-items: center;
    padding: 0.375em 0.75em 0.375em 0.375em;
    border-radius: 99em;
    transition: 0.25s ease;
    font-size: 28px;
    color: var(--tbase-blue);
    font-weight: bold;
  }

  label span:hover {
    background-color: #ffffff;
  }

  label span:before {
    display: flex;
    flex-shrink: 0;
    content: "";
    background-color: #fff;
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
    margin-right: 0.375em;
    transition: 0.25s ease;
    box-shadow: inset 0 0 0 0.125em var(--tbase-orange);
  }


`