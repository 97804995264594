import React, {useEffect, useState} from "react"
import {useParams} from "react-router-dom"

import {Axios} from "../../config"
import {HeaderComponent} from "../../components/Header"
import {SurveysHeaderBox} from "../../components/Surveys/box"
import {LoadingComponent} from "../../components/Loading"
import {QuestionListComponent} from "../../components/Surveys/questions_list"
import {QuestionEventsComponent} from "../../components/Surveys/questions_events"
import {useQuery} from "../../hooks/useQuery";
import {SurveysDiagramComponent} from "../../components/Surveys/diagram";
import {AssignUsersTable} from "../../datatables/AssignUsersTable";
import {AssignDatabaseTable} from "../../datatables/AssignDatabaseTable";
import {RegistersRowTable} from "../../datatables/RegistersRowTable";

export const SurveysIdV2PageComponent = () => {

    const {id} = useParams()
    const query = useQuery()
    const [loading, setLoading] = useState(true)
    const [selectedPage, setSelectedPage] = useState(()=>{
        switch (query.get("view"))
        {
            case "questions": return 0;
            case "events": return 1;
            case "databases": return 4;
            case "registers": return 5;
            default: return 0;
        }
    })

    const [survey, setSurvey] = useState({name: ''})
    const [brand, setBrand] = useState({name: '', brand_logo: null})
    const [campaign, setCampaign] = useState({name: ''})

    useEffect(() => {
        if(id) Axios.get("/surveys/" + id)
            .then((response) => {
                if(response.status === 200)
                {
                    setLoading(false)
                    setSurvey(response.data.survey)
                    setBrand(response.data.brand)
                    setCampaign(response.data.campaign)
                }
            })
    }, [id])

    if (loading) {
        return <LoadingComponent/>

    }

    return <>
        <HeaderComponent
            title={brand.name + " > " + campaign.name + " > " + survey.name}
        />
        <div className="row mt-5" />
        <div className="container-fluid mt-3">
            <SurveysHeaderBox selectedPage={selectedPage} onClick={(selected)=>setSelectedPage(selected)} logo={brand.brand_logo} />
            {selectedPage===0 && <QuestionListComponent idSurvey={id} />}
            {selectedPage===1 && <QuestionEventsComponent idSurvey={id} />}
            {selectedPage===2 && <SurveysDiagramComponent idSurvey={id} />}
            {selectedPage===3 && <AssignUsersTable idSurvey={id} />}
            {selectedPage===4 && <AssignDatabaseTable idSurvey={id} />}
            {selectedPage===5 && <RegistersRowTable idSurvey={id} />}
        </div>
    </>
}