import React from "react"
import {Modal as ModalComponent} from 'react-bootstrap'
import {Button, ModalStyle} from "./styles";

export const Modal = ({show = false, onClose, children, footer, size= "md"}) => {

    return (
        <ModalComponent
            show={show}
            onHide={onClose}
            backdrop="static"
            size={size}
            centered
        >
            <ModalComponent.Header style={{"borderBottom": "none"}}>
                <div/>
                <Button onClick={onClose} className="btn"><i className="far fa-times-circle"/></Button>
            </ModalComponent.Header>
            <ModalComponent.Body>
                <ModalStyle>
                    {React.Children.map(children, component => {
                        return component
                    })}
                </ModalStyle>
            </ModalComponent.Body>
            <ModalComponent.Footer style={{"borderTop": "none"}}>
                <ModalStyle>
                    {footer}
                </ModalStyle>
            </ModalComponent.Footer>
        </ModalComponent>
    )
}