import React from "react"
import {Modal} from "../components/Modal"
import {capitalize} from "../functions"

export const UpdatedRegisterRowModal = ({showed, onClose, register}) => {

    return (
        <Modal
            show={showed}
            onClose={onClose}
            size="lg"
            >
            <div>
                <h3>Registro #{register.id}</h3>
                <p className="mb-4">Copia de la información actualizada del registro.</p>

                {typeof register.updated_data === "object" &&
                (
                    <div className="mt-1">
                        {Object.values(register.updated_data).map((el, i) => {
                            return <div key={i} className="row mt-2">
                                <div className="col-4">
                                    <strong>{capitalize(Object.keys(register.updated_data)[i])}:</strong>
                                </div>
                                <div className="col-8">
                                    {el}
                                </div>
                            </div>
                        })}
                    </div>
                )}
            </div>
        </Modal>
    )
}