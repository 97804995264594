import React, {useContext, useState} from "react"

import {AlertContext} from "../providers/AlertContext"
import {Axios} from "../config"
import {Modal} from "../components/Modal"
import {LoadingComponent} from "../components/Loading"
import {DragAndDropComponent} from "../components/DragAndDrop"
import {reloadWithParams} from "../functions";

export const RegisterDatabaseModal = ({show, onClose, idSurvey}) => {

    const {successAlert, errorAlert} = useContext(AlertContext)
    const [loading, setLoading] = useState(false)
    const [database, setDatabase] = useState([{name: ''}])

    const handleSubmit = () => {
        setLoading(true)
        const data = new FormData()
        data.append('survey_id', idSurvey)
        if (database[0].name !== '') {
            data.append('database', database[0])
        }
        Axios.post("/registers", data)
            .then((response) => {
                if (response.status === 201) {
                    successAlert("Correcto", "Base de datos cargada", ()=>{
                        reloadWithParams(["view=databases"])
                    })
                }
            }).catch((e) => {
            errorAlert("Error", "No se pudo cargar la base.")
        }).finally(()=>setLoading(false))

    }


    return (
        <Modal show={show} onClose={onClose}>
            {loading ? (
                <LoadingComponent />
            ) : (
                <div>
                    <h3>Cargar nueva base de datos</h3>
                    <p>Por defecto la base estará activa.</p>

                    <div className="form-group mt-3">
                        <label htmlFor="">Base de datos:</label>
                        <DragAndDropComponent handle={file => setDatabase(file)} files={database}/>
                    </div>

                    <div className="form-group mt-5">
                        <div className="d-flex justify-content-center">
                            <button className="btn-blue" onClick={handleSubmit}><i
                                className="fas fa-user-plus"/> Cargar base
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </Modal>
    )
}