import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import {BoxDoughnut} from "./styles";

const DoughnutChart = ({title, labels, values}) => {

    const data = {
        labels: labels,
        datasets: [
            {
                label: title,
                data: values,
                backgroundColor: [
                    'rgba(255, 99, 132, 0.8)',
                    'rgba(54, 162, 235, 0.8)',
                    'rgba(255, 206, 86, 0.8)',
                    'rgba(75, 192, 192, 0.8)',
                    'rgba(153, 102, 255, 0.8)',
                    'rgba(255, 159, 64, 0.8)',
                    'rgba(89,255,64,0.8)',
                    'rgba(255,252,64,0.8)',
                    'rgba(64,112,255,0.8)',
                ]
            },
        ],
    };

    return (<BoxDoughnut>
            <Doughnut data={data}/>
            <h4>{title}</h4>
        </BoxDoughnut>
    )
}

export default DoughnutChart;