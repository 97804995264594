import React from "react"
import {Link} from "react-router-dom"

import {Container} from "./styles";

export const BoxComponent = ({link, title, image}) => (
    <Container>
        <Link to={link}>
            <div className="box h-100">
                <div className="d-flex justify-content-center align-items-center position-relative h-100">
                    <img src={image} alt={title}/>
                </div>
                <div className="d-flex justify-content-center">
                    <span>{title}</span>
                </div>
            </div>
        </Link>
    </Container>
)