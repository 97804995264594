import React from "react"

import {HeaderComponent} from "../../components/Header"
// import {TextButton} from "../../components/TextButton"
import {AddressesTable} from "../../datatables/AddressesTable"

export const ReportsAddressesPageComponent = () => {

    return (
        <>
            <div className="container-fluid">
                <HeaderComponent
                    title={"Direcciones"}
                />
                <div className="container">
                    <div className="row mt-5">
                        {/*<div className="row mt-3 mb-3">*/}
                        {/*    <div className="col-md-12 d-flex justify-content-center">*/}
                        {/*        <TextButton text="Descargar direcciones" icon="fas fa-download" onClick={() => {}} />*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="row mt-3">
                            <AddressesTable />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}