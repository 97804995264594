import React, {useContext, useEffect, useState} from "react"

import {AlertContext} from "../providers/AlertContext"
import {Axios} from "../config"
import {Modal} from "../components/Modal"
import {LoadingComponent} from "../components/Loading"
import {capitalize} from "../functions"

export const EditRegisterRowModal = ({showed, onClose, register}) => {

    const {successAlert, errorAlert} = useContext(AlertContext)

    const [loading, setLoading] = useState(false)
    const [values, setValues] = useState([])

    const handleEdit = () => {

        setLoading(true)
        const data = JSON.stringify({
            updated_data: values
        })

        Axios.put("/register-rows/" + register.id, data).then((response) => {
            if(response.status === 200) {
                successAlert("Correcto", "Registro editado.", () => {
                    window.location.reload()
                })
            }
        }).catch(() => {
            errorAlert("Error", "No se pudo editar el registro.")
        }).finally(()=>{
            setLoading(false)
        })
    }

    useEffect(() => {

        if(typeof register ==="undefined" || register===null || register==="") return

        Axios.get("/register-rows/" + register?.id).then((response) => {
            if (response.status === 200) {
                const _values = []
                Object.values(response.data.updated_data).map((el) => {
                    _values.push(el)
                    return 1
                })
                setValues(_values)
            }
        }).catch(() => {
            errorAlert("Error", "No se pudo editar el registro.")
        }).finally(()=>setLoading(false))
        //eslint-disable-next-line
    }, [register])

    const updateField = (index, value) => {
        const state = [...values]
        state[index] = value
        setValues(state)
    }

    return (
        <Modal
            show={showed}
            onClose={onClose}
            size="lg">
            {loading ? (
                <LoadingComponent/>
            ) : (
                <div>
                    <h3>Editar información del registro.</h3>
                    <p className="mb-4">Por seguridad se guardará una copia de la información orginal.</p>
                    {typeof register?.data === "object" &&
                    (
                        Object.values(register.data).map((el, i) => (
                            <div key={i} className="row mt-3">
                                <div className="col-3">
                                    <strong>{capitalize(Object.keys(register.data)[i])}:</strong>
                                </div>
                                <div className="col-9">
                                    <input type="text" className="w-100" value={values[i]}
                                           onChange={(e) => updateField(i, e.target.value)}/>
                                </div>
                            </div>
                        ))
                    )}
                    <div className="form-group mt-5">
                        <div className="d-flex justify-content-center">
                            <button className="btn-blue" onClick={handleEdit}>
                                <i className="fas fa-pen"/> Editar información
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </Modal>
    )
}