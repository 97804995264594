import React, {useEffect, useRef, useState} from "react";
import {FormElements} from "../Form/styles"

export const TypeQuestion3 = ({onChange, data}) => {

    const [active, setActive] = useState(-1)
    const [input, setInput] = useState("")
    const ref = useRef(null)

    useEffect(()=>{
        if(active===data.length) {
            ref.current.focus()
            onChange(input)
        }else {
            onChange(active.toString())
        }
    },[active, data.length, onChange, input])

    return (
        <div className="services-select-option">
            <ul style={{"listStyleType": "none"}}>

                {data.map( (element, index) => (
                    <li key={index} onClick={()=>setActive(index)}  className={active===index?"bg-white active":"bg-white"}><label>{element} <input type="radio" value={index} /></label></li>
                ))}

                <div className="row">
                    <div className="col-6">
                        <li onClick={()=>setActive(data.length)}  className={active===data.length?"bg-white active":"bg-white"}><label>Otro <input type="radio" name="otra" value="otro" /></label></li>
                    </div>
                    <div className="col-6">
                        <FormElements>
                            <input value={input} onChange={(e)=>setInput(e.target.value)} ref={ref} type="text" className="form-control-lg w-100 type3-input-fix" style={{border: active===data.length? "2px solid #e64302" : "2px solid #ededed"}} />
                        </FormElements>
                    </div>
                </div>

            </ul>
        </div>
    )
}