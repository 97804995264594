import styled from "styled-components"

export const Form = styled.form`
  width: 100%;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
  
  .toggle-password {
    font-size: 1.5rem;
    color: var(--tbase-orange);
  }
  
  button {
    padding: 1rem 0;
    color: white;
    font-size: 1.3rem;
    border-radius: 25px;
    background: linear-gradient(270deg, var(--third-color) 0%, var(--fourth-color) 100%);
    border: none;
  }
  
  .forgot-password {
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-size: 1.3rem;
      a {
        color: var(--fifth-color);
      }    
  }

  textarea {
    padding: 1rem;
    width: 100%;
    background-color: white;
    color: black;
    border-radius: 5px;
    border: 2px solid var(--secondary-orange);
    font-family: "Poppins", sans-serif;
    font-size: 1rem;
    min-height: 180px;

    :focus,
    :active,
    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus,
    :-webkit-autofill:active
    {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
      background-color: white;
      color: black;
    }
  }
  
`

export const TextAreaContainer = styled.div`
  textarea {
    padding: 1rem;
    border-radius: 5px;
    border: 2px solid var(--tbase-orange);
    font-family: "Poppins", sans-serif;
    font-size: 1rem;
    min-height: 130px;
  }
`

export const FormElements = styled.div`
  input {
    font-family: "Poppins", sans-serif;
    border: 2px solid var(--secondary-orange);
    padding: 0.5rem 1rem;
    border-radius: 5px;
    width: 100%;

    :focus {
      border-radius: 5px;
      outline: none;
      border-color: var(--primary-orange);
    }
    
  }

  .type3-input-fix {
    height: 65px !important;
  }
  
  textarea {
    border: 2px solid var(--secondary-orange);
    width: 100%;
    border-radius: 5px;
    padding: 1rem;
    min-height: 150px;
    font-size: 20px;

    :focus {
      border-radius: 5px;
      outline: none;
      border-color: var(--primary-orange);
    }
  }

  label {
    line-height: 50px;
    font-family: "Poppins", sans-serif;
    font-weight: bold;
    font-size: 20px;
    color: var(--tbase-blue);
  }
  
  .icon-delete {
    font-size: 22px;
    color: var(--tbase-orange);
    cursor: pointer;
    line-height: 50px;
  }

`