import React from "react"
import {TypeQuestion0} from "../TypeQuestion/0"
import {TypeQuestion1} from "../TypeQuestion/1"
import {TypeQuestion2} from "../TypeQuestion/2"
import {TypeQuestion3} from "../TypeQuestion/3"
import {TypeQuestion4} from "../TypeQuestion/4"
import {TypeQuestion5} from "../TypeQuestion/5"
import {TypeQuestion6} from "../TypeQuestion/6"

import QuestionImage from "../../assets/svg/question.svg"


export const SurveyQuestion = ({questionPosition, currentQuestion, farewellMessage, handleOnChange}) => {
 return (
    <>
        {questionPosition !== "final" ? (
            <div className="row mt-5">
                <div className="col-md-1">
                    <img src={QuestionImage} alt=""/>
                </div>
                <div className="col-md-11">
                    <h1>{currentQuestion.name ?? ""}</h1>
                </div>
            </div>
        ) : (
            <div className="row mt-5">
                <div className="col-md-12">
                    <h1>{farewellMessage}</h1>
                </div>
            </div>
        )}

        {questionPosition !== "final" && <div className="row mt-5">
            <div className="col-md-12">
                {currentQuestion.type === 0 && <TypeQuestion0 onChange={handleOnChange}/>}
                {currentQuestion.type === 1 && <TypeQuestion1 onChange={handleOnChange}/>}
                {currentQuestion.type === 2 && <TypeQuestion2 onChange={handleOnChange}/>}
                {currentQuestion.type === 3 && <TypeQuestion3 onChange={handleOnChange} data={currentQuestion.content}/>}
                {currentQuestion.type === 4 && <TypeQuestion4 onChange={handleOnChange} data={currentQuestion.content}/>}
                {currentQuestion.type === 5 && <TypeQuestion5 onChange={handleOnChange}/>}
                {currentQuestion.type === 6 && <TypeQuestion6 onChange={handleOnChange} data={currentQuestion.content}/>}
            </div>
        </div>}

        <div className="row mt-5"/>
    </>
 )
 }
