import React from "react"
import {Modal} from "../components/Modal"
import {capitalize} from "../functions"

export const OriginalRegisterRowModal = ({showed, onClose, auxMessage, register}) => {

    return (
        <Modal
            show={showed}
            onClose={onClose}
            size="lg"
            >
            <div>
                <h3>Registro #{register?.id}</h3>
                <p className="mb-4">Copia de la información orginal del registro.</p>

                {auxMessage != null && (
                    <div className="row mt-1">
                        <div className="col-12">
                            <p style={{color: "black"}}>{auxMessage}</p>
                        </div>
                    </div>
                )}

                {typeof register?.data === "object" &&
                (
                    <div className="mt-1">
                        {Object.values(register.data).map((el, i) => {
                            return <div key={i} className="row mt-2">
                                <div className="col-4">
                                    <strong>{capitalize(Object.keys(register.data)[i])}:</strong>
                                </div>
                                <div className="col-8">
                                    {el}
                                </div>
                            </div>
                        })}
                    </div>
                )}
            </div>
        </Modal>
    )
}