import {LoginPageComponent} from "./pages"
import {AdminPageComponent} from "./pages/dashboard/admin";
import {UsersPageComponent} from "./pages/users";
import {ExitPageComponent} from "./pages/exit";
import {NotFoundComponent} from "./pages/404";
import {AgentPageComponent} from "./pages/dashboard/agent";
import {BrandsPageComponent} from "./pages/brands";
import {CampaignsIdComponent} from "./pages/campaigns/[id]";
import {CampaignIdComponent} from "./pages/campaign/[id]";
import {SurveyPageComponent} from "./pages/survey/[id]";
import {SurveyQuestionPageComponent} from "./pages/survey/id";
import {SurveysIdV2PageComponent} from "./pages/surveys/[id]";
import {ReportsBySurveyPageComponent} from "./pages/reports/survey";
import {StaticsByAgentPageComponent} from "./pages/reports/agent";
import {StaticsAndReportPageComponent} from "./pages/reports";
import {ReportsAddressesPageComponent} from "./pages/reports/addresses";

export const Routes = [
    {
        path: "/",
        exact: true,
        component: LoginPageComponent,
        allowedRoles: [],
        settings: {
            menu: false
        }
    },
    {
        path: "/dashboard/admin",
        exact: true,
        component: AdminPageComponent,
        allowedRoles: [
            'ROLE_ADMIN',
        ],
        settings: {
            menu: true
        }
    },
    {
        path: "/dashboard/supervisor",
        exact: true,
        component: AdminPageComponent,
        allowedRoles: [
            'ROLE_ADMIN',
            'ROLE_SUPERVISOR'
        ],
        settings: {
            menu: true
        }
    },
    {
        path: "/dashboard/agent",
        exact: true,
        component: AgentPageComponent,
        allowedRoles: [],
        settings: {
            menu: true
        }
    },
    {
        path: "/users",
        exact: true,
        component: UsersPageComponent,
        allowedRoles: [
            'ROLE_ADMIN',
            'ROLE_SUPERVISOR'
        ],
        settings: {
            menu: true
        }
    },
    {
        path: "/reports-and-statics",
        exact: true,
        component: StaticsAndReportPageComponent,
        allowedRoles: [
            'ROLE_ADMIN',
            'ROLE_SUPERVISOR'
        ],
        settings: {
            menu: true
        }
    },
    {
        path: "/reports-by-survey",
        exact: true,
        component: ReportsBySurveyPageComponent,
        allowedRoles: [
            'ROLE_ADMIN',
            'ROLE_SUPERVISOR'
        ],
        settings: {
            menu: true
        }
    },
    {
        path: "/statics-by-agent",
        exact: true,
        component: StaticsByAgentPageComponent,
        allowedRoles: [
            'ROLE_ADMIN',
            'ROLE_SUPERVISOR'
        ],
        settings: {
            menu: true
        }
    },
    {
        path: "/reports-addresses",
        exact: true,
        component: ReportsAddressesPageComponent,
        allowedRoles: [
            'ROLE_ADMIN',
        ],
        settings: {
            menu: true
        }
    },
    {
        path: "/brands",
        exact: true,
        component: BrandsPageComponent,
        allowedRoles: [
            'ROLE_ADMIN',
        ],
        settings: {
            menu: true
        }
    },
    {
        path: "/campaigns/:id",
        exact: true,
        component: CampaignsIdComponent,
        allowedRoles: [
            'ROLE_ADMIN',
        ],
        settings: {
            menu: true
        }
    },
    {
        path: "/campaign/:id",
        exact: true,
        component: CampaignIdComponent,
        allowedRoles: [
            'ROLE_ADMIN',
        ],
        settings: {
            menu: true
        }
    },
    {
        path: "/surveys/:id",
        exact: true,
        component: SurveysIdV2PageComponent,
        allowedRoles: [
            'ROLE_ADMIN',
        ],
        settings: {
            menu: true
        }
    },
    {
        path: "/survey/:id",
        exact: true,
        component: SurveyPageComponent,
        allowedRoles: [],
        settings: {
            menu: true
        }
    },
    {
        path: "/survey/:survey_id/:question_position",
        exact: true,
        component: SurveyQuestionPageComponent,
        allowedRoles: [],
        settings: {
            menu: false
        }
    },
    {
        path: "/exit",
        exact: true,
        component: ExitPageComponent,
        allowedRoles: [],
        settings: {}
    },
    {
        path: null,
        exact: null,
        component: NotFoundComponent,
        allowedRoles: [],
        settings: {}
    }
]