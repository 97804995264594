import React, {useContext, useEffect, useState} from "react"
import Select from "react-select"

import {Modal} from "../components/Modal"
import {TextArea} from "../components/Form"
import {Axios, customSelectStyles} from "../config"
import {QuestionMultiAnswerComponent} from "../components/Question/multianswer"
import {LoadingComponent} from "../components/Loading"
import {AlertContext} from "../providers/AlertContext";
import {ButtonParam} from "../components/Question/styles";
import {clearQuestionOptions} from "../functions";

const questionTypeCatalog = [
    {label: "Si / No", value: 0},
    {label: "Seleccione del 1 al 10", value: 1},
    {label: "Respuesta libre", value: 2},
    {label: "Catálogo Unico", value: 3},
    {label: "Catálogo múltiple", value: 4},
    {label: "Likert", value: 5},
    {label: "Ranking", value: 6},
]

export const EditQuestionModal = ({show, onClose, currentQuestion, buttons}) => {

    const [loading, setLoading] = useState(false)
    const {confirmationAlert, errorAlert, successAlert} = useContext(AlertContext)

    const [question, setQuestion] = useState({
        id: null,
        name: "",
        position: null,
        type: null
    })
    const [catalog, setCatalog] = useState([])

    useEffect(() => {
        if (currentQuestion) {
            const _question = {...currentQuestion}
            _question.type = questionTypeCatalog[currentQuestion.type]
            setQuestion(_question)
        }
    }, [currentQuestion])

    const handleSubmit = () => {
        setLoading(true)

        const data = {
            name: question.name,
            type: question.type.value,
            content: clearQuestionOptions(catalog)
        }

        Axios.put("/questions/" + currentQuestion.id, JSON.stringify(data))
            .then((response) => {
                if (response.status === 200) {
                    successAlert("Correcto", "Pregunta actualizada con éxito.", ()=>{
                        window.location.reload()
                    })
                }
        }).catch(()=>{
            errorAlert("Error", "La pregunta no se pudo actualizar.")
        }).finally(()=>setLoading(false))
    }

    const confirmDeleteQuestion = () => {
        confirmationAlert("Eliminar pregunta",
            "¡Atención! eliminar la pregunta es algo que no se puede revertir, " +
            "se aconseja a solo utilizar esta opción durante la creación de la encuesta " +
            "pues también serán eliminadas todas las respuestas asignadas a esta pregunta.",
            () => {
                setLoading(true)
                Axios.delete("/questions/" + currentQuestion.id).then((response)=>{
                    if (response.status === 200) {
                        successAlert("Correcto", "Pregunta eliminada con éxito.", ()=>{
                            window.location.reload()
                        })
                    }
                }).catch(()=>{
                    errorAlert("Error", "La pregunta no se pudo eliminar.")
                }).finally(()=>setLoading(false))
            }
        )
    }

    const addParam = param => {
        setQuestion({...question, name: question.name+" :"+param+": "})
    }

    if (loading) {
        return <Modal show={show} onClose={onClose} size="lg"><LoadingComponent/></Modal>
    }

    return (
        <Modal show={show} onClose={onClose} size="lg">
            <h3>Editar #{question.position + 1}</h3>
            <p>Todos los campos son opcionales</p>

            <div className="row">
                <div className="col-md-12">
                    <div className="form-group mt-3">
                        {buttons.map((val, i) => (
                            <ButtonParam key={i} onClick={() => addParam(val)}>
                                :{val}:
                            </ButtonParam>
                        ))}
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div className="form-group mt-3">
                        <label>Pregunta:</label>
                        <TextArea className="w-100" value={question.name}
                                  onChange={(e) => setQuestion({...question, name: e.target.value})}/>
                    </div>
                    <div className="form-group mt-3">
                        <label>Tipo de respuesta:</label>
                        <Select
                            styles={customSelectStyles}
                            placeholder={"Seleccionar el tipo..."}
                            options={questionTypeCatalog}
                            value={question.type}
                            onChange={e => setQuestion({...question, type: e})}
                        />
                    </div>

                    {(question.type?.value === 3 || question.type?.value === 4 || question.type?.value === 6) && (
                        <div className="form-group mt-4 mb-3">
                            <label>Respuesta:</label>
                            <QuestionMultiAnswerComponent catalogValues={e => setCatalog(e)}
                                                          currentCatalog={currentQuestion.content}/>
                        </div>
                    )}

                    <div className="form-group mt-4">
                        <div className="d-flex justify-content-center">
                            <button className="btn-blue" onClick={handleSubmit}><i
                                className="fas fa-pen"/> Actualizar
                            </button>
                        </div>
                    </div>

                    <p onClick={confirmDeleteQuestion} className="text-end mt-4 mb-0" style={{cursor: "pointer"}}><i
                        className="fas fa-trash"/> Eliminar</p>

                </div>
            </div>


        </Modal>
    )
}
