import styled from "styled-components";

export const Container = styled.div`
    a {
      text-decoration: none;
      font-family: "Poppins", sans-serif;
      font-size: 22px;
      color: var(--tbase-blue);
      font-weight: bold;
      
      :hover, :focus, :active {
        color: var(--tbase-orange);
      }

      @media screen and (max-width: 768px) {
        margin-bottom: 1.5rem;
      }
    }
  
    .box {
      background-color: white;
      border-radius: 5px;
      padding: 35px 15px;
      -webkit-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.15);
      box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.15);
      border: 2px solid white;
      position: relative;

      :hover {
        border: 2px solid var(--tbase-orange);
      }
      
    }
  
`