import React, {useEffect, useState} from "react"
import CountUp from 'react-countup'
import {ReportsBox} from "./box"
import {Axios} from "../../config"
import {dateToTimestamp} from "../../functions"
import {LoadingComponent} from "../Loading";

export const ReportsUserStatics = ({agentId, startDate, endDate}) => {

    const [loading, setLoading] = useState(false)

    const [rated, setRated] = useState(0)
    const [completed, setCompleted] = useState(0)

    useEffect(()=>{
        setLoading(true)
    },[agentId, startDate, endDate])

    useEffect(()=>{
        if(loading)
        {
            const data = new FormData()
            data.append("start_at", dateToTimestamp(startDate))
            data.append("end_at", dateToTimestamp(endDate))
            Axios.post("/users/statics/" + agentId, data)
                .then((response)=>{
                    setRated(response.data.rated)
                    setCompleted(response.data.completed)
            }).finally(()=>{
                setLoading(false)
            })
        }
        // eslint-disable-next-line
    },[loading])

    if(loading)
    {
        return <LoadingComponent />
    }

    return (
        <>
            <div className="row">
                <div className="col-md-4">
                    <ReportsBox title="Registros calificados" icon="fas fa-chart-pie">
                        <CountUp end={rated} />
                    </ReportsBox>
                </div>
                <div className="col-md-4 mt-5 mt-sm-0">
                    <ReportsBox title="Encuestas" icon="far fa-list-alt">
                        <CountUp end={completed} />
                    </ReportsBox>
                </div>
            </div>
        </>
    )
}