import React, {useContext, useEffect, useState} from "react"

import {Container} from "./styles"
import {Axios} from "../../config"
import {LoadingComponent} from "../Loading"
import {TextButton} from "../TextButton"
import {RegisterEventModal} from "../../modals/RegisterEvent";
import {AlertContext} from "../../providers/AlertContext";
import {reloadWithParams} from "../../functions";

export const QuestionEventsComponent = ({idSurvey}) => {

    const { confirmationAlert } = useContext(AlertContext)
    const [loading, setLoading] = useState(true)
    const [questions, setQuestions] = useState([])
    const [questionEvents, setQuestionEvents] = useState([])
    const [modalAdd, setModalAdd] = useState(false)

    const handleDeleteEvent = id => {

        confirmationAlert("Eliminar evento", "¿Confirma eliminar el evento?", ()=>{
            setLoading(true)
            Axios.delete("/events/" + id)
                .then(()=>{})
                .finally(()=>{
                    reloadWithParams(["view=events"])
                })
        })

    }

    useEffect(() => {
        if(idSurvey) {
            Axios.get("/questions/survey/" + idSurvey).then((response) => {
                const questions = response.data.questions
                const _events = questions.map((question)=>{return question.events})
                setQuestions(questions)
                setQuestionEvents(_events)
            }).finally(()=>{
                setLoading(false)
            })
        }
    }, [idSurvey])


    if(loading)
    {
        return <LoadingComponent />
    }

    return (
        <>
            <Container className="container mt-3 pt-4">
                <div className="row mt-3 mb-3">
                    <div className="col-md-12 d-flex justify-content-center">
                        <TextButton text="Agregar evento" icon="fas fa-plus-circle" onClick={() => setModalAdd(true)}/>
                    </div>
                </div>

                {questionEvents.map((events)=> {
                    return events.map((event)=>(
                        <div className="py-3 questions-events">
                            <div className="row">
                                <div className="col-sm-1 d-flex flex-column justify-content-center align-items-center">
                                <span className="circle me-3" onClick={()=>handleDeleteEvent(event.id)}>
                                    <i className="fas fa-trash"/>
                                </span>
                                </div>
                                <div className="col-sm-11 d-flex justify-content-center">
                                    <img src={event.svg} alt={event.id} className="img-fluid" />
                                </div>
                            </div>
                        </div>
                    ))
                })}

                <div className="row mt-5 mb-3">
                    <div className="col-md-12 d-flex justify-content-center">
                        <TextButton text="Agregar evento" icon="fas fa-plus-circle" onClick={() => setModalAdd(true)}/>
                    </div>
                </div>
            </Container>
            <RegisterEventModal show={modalAdd} onClose={()=>setModalAdd(false)} questions={questions} />
        </>
    )
}