import styled from "styled-components"

export const Container = styled.div`
  display: inline-block;
  cursor: pointer;
  
  span {
    font-weight: bold;
    font-size: 18px;
    padding: 0 10px;
    color: var(--tbase-blue);
    
    @media screen and (max-width: 768px) {
      font-size: 16px;
    }
    
  }
  
  i {
    background: linear-gradient(180deg, var(--primary-orange) 0%, var(--tbase-orange) 100%);
    border-radius: 50%;
    padding: 0.3rem;
    color: white;
    font-size: 16px;
    margin-left: 6px;


    @media screen and (max-width: 768px) {
      padding: 0.4rem;
      font-size: 14px;
    }
    
  }
`