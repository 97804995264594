import React from "react"
import {Router, Switch, Route} from "react-router"
import {createBrowserHistory} from "history"
import es from "date-fns/locale/es"
import {registerLocale} from "react-datepicker"

import {SessionProvider} from "./providers/SessionContext"
import {AlertProvider} from "./providers/AlertContext"
import {LayoutComponent} from "./components/Layout"
import {Routes} from "./routes"

import "bootstrap/dist/css/bootstrap.min.css"
import "@fortawesome/fontawesome-free/css/all.min.css"
import "./assets/styles/global.css"
import "./assets/styles/wizard.scss"

const history = createBrowserHistory()
registerLocale('es', es)

const Application = () => (
    <SessionProvider>
        <AlertProvider>
            <Router history={history}>
                <Switch>
                    {
                        Routes.map((route, i) => {
                            return (
                                <Route key={i} path={route.path} exact={route.exact}>
                                    <LayoutComponent menu={route.settings.menu}
                                                     allowedRoles={route.allowedRoles}>
                                        <route.component/>
                                    </LayoutComponent>
                                </Route>
                            )
                        })
                    }
                </Switch>
            </Router>
        </AlertProvider>
    </SessionProvider>
)

export default Application