import React, {useContext, useEffect, useState} from "react"
import {MenuComponent} from "../Menu";
import {AlertContext} from "../../providers/AlertContext";
import {SessionContext} from "../../providers/SessionContext";
import {Main} from "./styles";

export const LayoutComponent = ({children, menu, allowedRoles}) => {

    const { errorAlert } = useContext(AlertContext)
    const { session } = useContext(SessionContext)
    const [denied, setDenied] = useState(false)

    useEffect(()=>{
        if(allowedRoles.length>0 && !allowedRoles.includes(session.user.role)) {
            setDenied(true)
            errorAlert("Acceso denegado", "No tienes permiso para ingresar a esta página", ()=>{window.location.href = "/"})
        }
    },[allowedRoles, session.user.role, errorAlert])

    return (
        <>
            {menu && !denied && <MenuComponent />}
            <Main role="main" fullScreen={menu && !denied}>
                <div className="container-fluid p-0">
                    {!denied && React.Children.map(children, component=>{return component})}
                </div>
            </Main>
            <div style={{position: "fixed",  bottom: "5px", right: "20px"}}>Versión: {process.env.REACT_APP_VERSION}</div>
        </>
    )
}