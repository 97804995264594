export function translateDayName(day) {
    switch (day){
        case 1: return "Lunes"
        case 2: return "Martes"
        case 3: return "Miércoles"
        case 4: return "Jueves"
        case 5: return "Viernes"
        case 6: return "Sábado"
        default: return "Domingo"
    }
}

export function translateMonthName(month){
    switch (month) {
        case 0: return "Enero"
        case 1: return "Febrero"
        case 2: return "Marzo"
        case 3: return "Abril"
        case 4: return "Mayo"
        case 5: return "Junio"
        case 6: return "Julio"
        case 7: return "Agosto"
        case 8: return "Septiembre"
        case 9: return "Octubre"
        case 10: return "Noviembre"
        default: return "Diciembre"
    }
}

export function buildWeek(dayOfWeek, offset= 0) {
    const start = new Date()
    const end = new Date()
    switch (dayOfWeek) {
        case 1:
            start.setDate(start.getDate() - offset)
            end.setDate(end.getDate()+6 - offset)
            break
        case 2:
            start.setDate(start.getDate()-1 - offset)
            end.setDate(end.getDate()+5 - offset)
            break
        case 3:
            start.setDate(start.getDate()-2 - offset)
            end.setDate(end.getDate()+4 - offset)
            break
        case 4:
            start.setDate(start.getDate()-3 - offset)
            end.setDate(end.getDate()+3 - offset)
            break
        case 5:
            start.setDate(start.getDate()-4 - offset)
            end.setDate(end.getDate()+2 - offset)
            break
        case 6:
            start.setDate(start.getDate()-5 - offset)
            end.setDate(end.getDate()+1 - offset)
            break
        default:
            start.setDate(start.getDate()-6 - offset)
            end.setDate(end.getDate() - offset)
            break

    }
    return[start, end]
}

export function buildMonth(dayOfMonth, offset= 0) {
    const start = new Date()
    const end = new Date()
    const daysCurrentMonth = new Date(start.getFullYear(), start.getMonth(), 0).getDate()
    const daysPastMonth = new Date(start.getFullYear(), start.getMonth()-1, 0).getDate()
    const fixCurrent= offset * daysCurrentMonth
    const fixPast= offset * daysPastMonth
    start.setDate(start.getDate() - dayOfMonth + 1 - fixCurrent)
    end.setDate(end.getDate() + (daysCurrentMonth-dayOfMonth +1) - fixPast)
    return[start, end]
}