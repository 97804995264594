import React, {useEffect, useState} from "react"

import {Axios} from "../config"
import {LoadingComponent} from "../components/Loading"
import {DatatableComponent} from "../components/Datatable"
import {UpdatedRegisterRowModal} from "../modals/UpdatedRegisterRow";

export const AddressesTable = () => {

    const [loading, setLoading] = useState(true)
    const [values, setValues] = useState([])
    const [modalUpdatedData, setModalUpdatedData] = useState(false)
    const [currentRegister, setCurrentRegister] = useState({
        id: null
    })

    const getRegister = id => {
        Axios.get(`/register-rows/${id}`).then((response)=>{
            setCurrentRegister(response.data)
        }).finally(()=>{
            setModalUpdatedData(true)
        })
    }

    const viewUpdatedDataColumn = (value, tableMeta, updateValue) => {
        return (
            <div className="d-flex justify-content-center">
                <span className="circle-icon" onClick={()=>getRegister(value)}>
                    <i className="fas fa-eye " style={{cursor: "pointer"}} />
                </span>
            </div>
        )
    }

    const mapColumn = (value, tableMeta, updateValue) => {
        return <a href={value} target="_blank"  rel="noreferrer" ><span className="circle-icon"><i className="fas fa-link" /></span></a>
    }

    const columns = [
        {
            label: "Registro",
            name: "register_row_id",
            options: {
                customBodyRender: viewUpdatedDataColumn,
                filter: false
            }
        },
        {
            label: "Nombre",
            name: "name"
        },
        {
            label: "Dirección",
            name: "address"
        },
        {
            label: "Mapa",
            name: "url",
            options: {
                filter: false,
                customBodyRender: mapColumn
            }
        }
    ]

    useEffect(() => {
        Axios.get("/addresses").then((response) => {
            setValues(response.data)
        }).finally(() => {
            setLoading(false)
        })
        // eslint-disable-next-line
    }, [])

    if (loading) {
        return <LoadingComponent/>
    }

    return (
        <>
            <div className="container mt-3 p-3" style={{backgroundColor: "white", borderRadius: "25px"}}>
                <DatatableComponent columns={columns} data={values} />
            </div>
            <UpdatedRegisterRowModal register={currentRegister}  showed={modalUpdatedData} onClose={() => setModalUpdatedData(false)}/>
        </>
    )
}