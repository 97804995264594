import React, {useContext, useEffect, useState} from "react"

import {Axios} from "../config"
import {LoadingComponent} from "../components/Loading"
import {DatatableComponent} from "../components/Datatable"
import {SwitchOptions} from "../components/SwitchOptions"
import {AlertContext} from "../providers/AlertContext"
import {fullDateColumn, reloadWithParams} from "../functions"
import {TextButton} from "../components/TextButton"
import {RegisterDatabaseModal} from "../modals/RegisterDatabase"

export const AssignDatabaseTable = ({idSurvey}) => {

    const { successAlert, errorAlert, confirmationAlert} = useContext(AlertContext)
    const [loading, setLoading] = useState(true)
    const [databases, setDatabases] = useState([])
    const [values, setValues] = useState([])
    const [clickProtection, setClickProtection] = useState(false)
    const [modalAdd, setModalAdd] = useState(false)


    const handleUpdateDatabase = (id, index) => {
        if(clickProtection) return
        setClickProtection(true)
        const _values = [...values]
        const message =  _values[index] ? "Se desactivo la base seleccionada" : "Se activo la base seleccionada"
        _values[index] = !_values[index]
        const data = {
            status: _values[index]
        }
        Axios.put("/registers/" + id, JSON.stringify(data)).then((response)=>{
            successAlert("Correcto", message)
            setValues(_values)
        }).catch(()=>{
            errorAlert("Error", "No se pudo actualizar la base")
        }).finally(()=>setClickProtection(false))
    }

    const handleDeleteDatabase = id => {
        confirmationAlert("Atención", "Al eliminar la base se eleminará cualquer respuesta asignada a dichos registros, " +
            "se recomienda solo usar esta opción en la creación de la encuesta.", ()=>{
            Axios.delete("/registers/" + id).then(()=>{
                successAlert("Correcto", "Base de datos eliminada", ()=>{
                    reloadWithParams(["view=databases"])
                })
            }).catch(()=>{
                errorAlert("Error", "No se pudo eliminar la base")
            })
        })
    }

    const switchButtonColumn = (value, tableMeta, updateValue) => {
        return (
            <SwitchOptions id="us" option="Activa" state={values[tableMeta.rowIndex]} onChange={()=>handleUpdateDatabase(value, tableMeta.rowIndex)} />
        )
    }

    const deleteColumn = (value, tableMeta, updateValue) => {
        return (
            <div className="d-flex justify-content-center justify-content-sm-start">
                <span className="circle-icon" onClick={()=>handleDeleteDatabase(value)}>
                    <i className="fas fa-trash " style={{cursor: "pointer"}} />
                </span>
            </div>
        )
    }

    const columns = [

        {
            label: "Nombre",
            name: "name"
        },
        {
            label: "Cargada el",
            name: "created_at",
            options: {
                customBodyRender: fullDateColumn
            }
        },
        {
            label: "Acción",
            name: "id",
            options: {
                customBodyRender: switchButtonColumn,
                filter: false
            }
        },
        {
            label: "Eliminar",
            name: "id",
            options: {
                customBodyRender: deleteColumn,
                filter: false
            }
        },
    ]

    useEffect(() => {
        Axios.get("/registers/survey/" + idSurvey).then((response) => {
            const databases = response.data
            const _values = []
            databases.forEach((database, i)=>{
                _values[i] = database.status
            })
            setDatabases(databases)
            setValues(_values)
        }).finally(() => {
            setLoading(false)
        })
        // eslint-disable-next-line
    }, [])

    if (loading) {
        return <LoadingComponent/>
    }

    return (
        <>
            <div className="container mt-5 p-3" style={{backgroundColor: "white", borderRadius: "25px"}}>
                <div className="row mt-3 mb-3">
                    <div className="col-md-12 d-flex justify-content-center">
                        <TextButton text="Agregar base de datos" icon="fas fa-plus-circle" onClick={() => setModalAdd(true)}/>
                    </div>
                </div>
                <DatatableComponent columns={columns} data={databases} />
                <div className="row mt-5 mb-3">
                    <div className="col-md-12 d-flex justify-content-center">
                        <TextButton text="Agregar base de datos" icon="fas fa-plus-circle" onClick={() => setModalAdd(true)}/>
                    </div>
                </div>
            </div>
            <RegisterDatabaseModal show={modalAdd} onClose={()=>setModalAdd(false)} idSurvey={idSurvey} />
        </>
    )
}