import styled from "styled-components";

export const Container = styled.div`

  position: relative;
  background-color: white;
  border-radius: 5px;
  padding: 35px 15px;
  -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
  border: 2px solid white;
  min-height: 270px;

  :hover {
    -webkit-box-shadow: 0px 0px 12px 0px rgb(183, 225, 246);
    box-shadow: 0px 0px 12px 0px rgb(183, 225, 246);
  }

  img {
    height: 150px;
    width: 150px;
    object-fit: cover;
    border-radius: 50%;
  }

  h4 {
    color: var(--tbase-blue);
    font-weight: bold;
    max-height: 90px;
    overflow: hidden;
    white-space: inherit;
    text-overflow: ellipsis;
    font-size: 1rem;
  }

  span.high {
    font-weight: bold;
    font-size: 16px;
    color: var(--primary-orange);
  }

  p.status {
    height: 25px;
    width: 25px;
    position: absolute;
    top: 5px;
    left: 5px;
    border-radius: 50%;
  }

  span.from {
    font-size: 14px;
    font-weight: bold;
  }

  p.green {
    background: linear-gradient(143deg, rgba(137, 233, 0, 1) 0%, rgba(0, 168, 0, 1) 100%);
  }

  p.red {
    background: linear-gradient(143deg, rgba(245, 53, 53, 1) 0%, rgba(195, 0, 0, 1) 100%);
  }


  p.email {
    position: absolute;
    bottom: 0;
    left: 5px;
    font-weight: bold;
    color: #c0c0c0;
    font-size: 14px;
    cursor: pointer;
  }
  
  .button-config {
    position: absolute;
    bottom: 10px;
    right: 5px;
    color: var(--tbase-orange);
    cursor: pointer;
    font-size: 2rem;
  }

`