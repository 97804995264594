import styled from "styled-components";

export const DivDrag = styled.div`
  width: 100%;
  background-color: white;
  border: 2px solid var(--tbase-orange);
  height: 7rem;
  padding: 1.5rem;
  border-radius: 10px;
`

export const Span = styled.span`
  color: var(--dark-blue);
  font-weight: bold;
  font-size: 1rem;
  display:table-cell;
  vertical-align:middle;
`