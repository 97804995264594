import React, {useContext, useEffect, useState} from "react"
import Select from "react-select"

import {Modal} from "../components/Modal"
import {Axios, customSelectStyles} from "../config"
import {LoadingComponent} from "../components/Loading"
import {EventCatalogComponent} from "../components/Events/event_catalogs";
import {AlertContext} from "../providers/AlertContext";
import {reloadWithParams} from "../functions";

export const RegisterEventModal = ({show, onClose, questions}) => {

    const {errorAlert, continueAlert} = useContext(AlertContext)
    const [loading, setLoading] = useState(false)
    const [newEvent, setNewEvent] = useState({
        type: null
    })
    const [selectedQuestion, setSelectedQuestion] = useState(null)
    const [options, setOptions] = useState([])
    const [catalog, setCatalog] = useState(null)
    const [jumpToQuestion, setJumpToQuestion] = useState(null)


    const handleSubmit = () => {

        if(newEvent.type === null || selectedQuestion=== null || options.length === 0)
        {
            return
        }

        setLoading(true)

        const data = new FormData()
        data.append("answer", catalog.value)
        data.append("type", newEvent.type.value)
        data.append("position", jumpToQuestion?.value ?? 0)
        data.append("question_id", selectedQuestion.value)

        Axios.post("/events", data).then((response) => {
            if (response.status === 201) {
                continueAlert("Evento creado", "Agregar otro evento",
                    ()=>{
                        setSelectedQuestion(null)
                        setCatalog(null)
                        setNewEvent({type: null})
                        setJumpToQuestion(null)
                    },
                    ()=>{
                        reloadWithParams(["view=events"])
                    })
            }
        }).catch(() => {
            errorAlert("Error", "No se pudo crear el evento.")
        }).finally(()=>{
            setLoading(false)
        })
    }

    const refreshCurrentQuestion = e => {
        setSelectedQuestion(e)
        setCatalog(null)
        setNewEvent({type: null})
        setJumpToQuestion(null)
    }

    const refreshTypeEvent = e => {
        setNewEvent({...newEvent, type: e})
        setJumpToQuestion(null)
    }

    useEffect(()=>{
        const _options = []
        if(selectedQuestion?.content)
        {
            selectedQuestion.content.forEach(
                (option, index) => _options.push({label: option, value: index})
            )
        }
        _options.push({label: 'Cualquier respuesta', value: -1})
        setOptions(_options)
    },[selectedQuestion])


    if (loading) {
        return <Modal show={show} onClose={onClose} size="lg"><LoadingComponent/></Modal>
    }

    return (
        <Modal show={show} onClose={onClose} size="lg">
            <h3>Nuevo evento</h3>
            <p>Crear nuevo evento</p>

            <div className="form-group mt-3">
                <label>Pregunta:</label>
                <Select
                    styles={customSelectStyles}
                    placeholder={"Seleccionar pregunta..."}
                    options={questions.map((question, index)=>{return{label: (index+1) + ". " + question.name, value:question.id, type:question.type, content: question.content}})}
                    value={selectedQuestion}
                    onChange={refreshCurrentQuestion}
                />
            </div>

            {selectedQuestion !== null && <div className="form-group mt-3">
                <label>Si la respuesta es:</label>
                <EventCatalogComponent catalogType={selectedQuestion.type} catalog={catalog} setCatalog={setCatalog} options={options} />
            </div>}

            {catalog !== null && <div className="form-group mt-3">
                <label>Tipo de evento:</label>
                <Select
                    styles={customSelectStyles}
                    placeholder={"Seleccionar el tipo..."}
                    options={[
                        {label: "Terminar encuesta", value: 0},
                        {label: "Saltar a pregunta", value: 1},
                    ]}
                    value={newEvent.type}
                    onChange={refreshTypeEvent}
                />
            </div>}

            {newEvent.type?.value === 1 &&
                <div className="form-group mt-3">
                    <label>Saltar a pregunta:</label>
                    <Select
                        styles={customSelectStyles}
                        placeholder={"Seleccionar pregunta..."}
                        options={questions.map((question, index) =>
                            {
                                return {
                                    label: (index+1) + ". " + question.name,
                                    value:question.id,
                                    type:question.type
                                }
                            }).filter((question) =>
                            {
                                return selectedQuestion.value !== question.value
                            })
                        }
                        value={jumpToQuestion}
                        onChange={e=>setJumpToQuestion(e)}
                    />
                </div>
            }


            <div className="form-group mt-4">
                <div className="d-flex justify-content-center">
                    <button className="btn-blue" onClick={handleSubmit}><i
                        className="fas fa-pen"/> Agregar evento
                    </button>
                </div>
            </div>

        </Modal>
    )
}
