import React, {useEffect, useState} from "react"

import {Container} from "./styles"
import {Axios} from "../../config"
import {EditQuestionModal} from "../../modals/EditQuestion"
import {LoadingComponent} from "../Loading"
import {TextButton} from "../TextButton"
import {RegisterQuestionModal} from "../../modals/RegisterQuestion"

export const QuestionListComponent = ({idSurvey}) => {

    const [loading, setLoading] = useState(true)
    const [questions, setQuestions] = useState([])
    const [modalEdit, setModalEdit] = useState(false)
    const [modalAdd, setModalAdd] = useState(false)
    const [currentQuestion, setCurrentQuestion] = useState()
    const [buttons, setButtons] = useState([])

    useEffect(() => {
        if(idSurvey) {
            Axios.get("/questions/survey/" + idSurvey).then((response) => {
                setQuestions(response.data.questions)
                setLoading(false)
            })
            Axios.get(`/surveys/${idSurvey}/registers-headers`).then((response)=>{
                setButtons(response.data)
            })
        }
    }, [idSurvey])

    const editQuestion = (question) => {
        setCurrentQuestion(question)
        setModalEdit(true)
    }

    /**
     * @define
     * YES/NO: 0
     * 1-10: 1
     * FREE: 2
     * CATALOG-UNIQUE: 3
     * CATALOG-MULTIPLE: 4
     * LIKERT: 5
     */

    if(loading)
    {
        return <LoadingComponent />
    }

    return (
        <>
            <Container className="container mt-3 pt-4">

                <div className="row mt-3 mb-3">
                    <div className="col-md-12 d-flex justify-content-center">
                        <TextButton text="Agregar pregunta" icon="fas fa-plus-circle" onClick={() => setModalAdd(true)}/>
                    </div>
                </div>

                {questions.map((question, index) => (
                    <div key={index} className="py-3 questions-list" onClick={()=>editQuestion(question)}>
                        <div className="row">
                            <div className="col-sm-1 d-flex justify-content-center">
                                <span className="circle">
                                    {parseInt(question.position) + 1}
                                </span>
                            </div>
                            <div className="col-sm-11" style={{lineHeight: "35px"}}>
                                {question.name}
                            </div>
                        </div>
                    </div>
                ))}

                <div className="row mt-5 mb-3">
                    <div className="col-md-12 d-flex justify-content-center">
                        <TextButton text="Agregar pregunta" icon="fas fa-plus-circle" onClick={() => setModalAdd(true)}/>
                    </div>
                </div>
            </Container>
            <EditQuestionModal show={modalEdit} onClose={()=>setModalEdit(false)} currentQuestion={currentQuestion} buttons={buttons} />
            <RegisterQuestionModal show={modalAdd} onClose={()=>setModalAdd(false)} buttons={buttons} surveyId={idSurvey} />
        </>
    )
}