import React from "react";
import {RadioYN} from "./styles.";

export const TypeQuestion0 = ({onChange}) => {


    return (
        <RadioYN>
            <div className="row mt-5 mb-5">
                <div className="col-md-6">
                    <div className="d-flex justify-content-end">
                        <label>
                            <input type="radio" name="radio"/>
                            <span onClick={()=>onChange("0")}>Si</span>
                        </label>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="d-flex justify-content-start">
                        <label>
                            <input type="radio" name="radio"/>
                            <span onClick={()=>onChange("1")}>No</span>
                        </label>
                    </div>
                </div>
            </div>


        </RadioYN>
    )
}