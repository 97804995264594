import React from "react";
import {FormElements} from "../Form/styles"

export const TypeQuestion2 = ({onChange}) => {

    return (
        <div className="row mt-5 mb-5">
            <div className="col-md-12">
                <FormElements>
                    <textarea onChange={(e)=>onChange(e.target.value)} />
                </FormElements>
            </div>
        </div>
    )
}