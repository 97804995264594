import styled from "styled-components";

export const Title = styled.div`
  background: var(--orange-b);
  background: linear-gradient(90deg, rgba(255, 73, 0, 1) 0%, rgba(255, 128, 0, 1) 100%);
  color: white;
  font-size: 20px;
  width: 100%;
  padding: 1rem;
`

export const Button = styled.button`
  z-index: 999;
  i {
    color: var(--tbase-orange);
    font-size: 1.5rem;
  }
`

export const ModalStyle = styled.div`
  position: relative;
  margin-top: -90px;
  
  h3 { 
    color: var(--tbase-blue);
    font-weight: bold;
    font-size: 1.3rem;
  }
  
  p {
    color: var(--tbase-orange);
    font-weight: bold;
    font-size: 1rem;
  }
  
  label {
    color: var(--tbase-blue);
    font-family: "Poppins", sans-serif;
    font-size: 0.8rem;
    font-weight: bold;
    margin-bottom: 9px;
  }

  input {
    padding: 0.5rem 1rem;
    background-color: white;
    color: black;
    border-radius: 5px;
    border: 2px solid var(--tbase-orange);
    font-family: "Poppins", sans-serif;
    font-size: 1rem;

    :focus,
    :active
    {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
      border-top: 3px solid var(--tbase-orange);
      border-bottom: 3px solid var(--tbase-orange);
    }
  }

  .bl-none {
    border-left: none;
  }

  .br-none {
    border-right: none;
  }
  
  .border-radius-right-0{
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  .border-radius-left-0{
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
  
  .input-group-text {
    background-color: white;
    border-top: 2px solid var(--tbase-orange);
    border-left: 2px solid var(--tbase-orange);
    border-bottom: 2px solid var(--tbase-orange);
    padding: 0.5rem 1rem;
  }
  
  .bw-left {
    border-right: 2px solid var(--tbase-orange);
    border-left: 0;
    span {
      color: var(--tbase-orange);
      padding-top: 0;
      padding-bottom: 0.2rem;
    }
  }
  
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
    
    li {
      background-color: white;
      color: var(--dark-blue);
      font-weight: bold;
      font-size: 0.8rem;
      padding: 0.5rem 1rem;
      border-bottom: 1px dotted #dadada;

      :hover {
        transition: var(--transition-speed);
        background-color: #f7ead8;
      }
      
      span {
        margin-right: 10px; 
      }
      
    }
  }
  
`