import React, {useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {HeaderComponent} from "../../components/Header";
import {CampaignBoxComponent} from "../../components/Campaign/box";
import {TextButton} from "../../components/TextButton";
import {LoadingComponent} from "../../components/Loading";
import {SessionContext} from "../../providers/SessionContext";
import {setComponentsPerCols} from "../../functions";
import {RegisterCampaignModal} from "../../modals/RegisterCampaign";
import {Axios} from "../../config";

export const CampaignsIdComponent = () => {

    const {id} = useParams()

    const {session} = useContext(SessionContext)
    const [loading, setLoading] = useState(true)
    const [modal, setModal] = useState(false)
    const [brand, setBrand] = useState({name: '', brand_logo: null})
    const [campaigns, setCampaigns] = useState([])

    useEffect(() => {
        Axios.get("/campaigns/brand/" + id)
            .then((response) => {
                if (response.status === 200) {
                    setLoading(false)
                    setBrand(response.data.brand)
                    setCampaigns(setComponentsPerCols(response.data.campaigns, 3))
                }
            })
    }, [session.token, id])

    if (loading) {
        return <LoadingComponent/>

    }

    return <>
        <div className="container-fluid">
            <HeaderComponent
                title={brand.name + " > Campañas"}
            />
            <div className="pt-5"/>
            <div className="container-fluid">

                <div className="row">
                    <div className="col-md-3">
                        <div className="d-flex justify-content-center">
                            <img src={brand.brand_logo} alt="" style={{width: "200px", objectFit: "contain"}}/>
                        </div>
                        <h5 className="mt-2 text-center">{brand.name}</h5>
                    </div>
                    <div className="col-md-9 mt-3 mt-sm-0">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <TextButton text="Crear campaña" icon="fas fa-plus-circle"
                                                onClick={() => setModal(true)}/>
                                </div>
                            </div>
                            {campaigns.map((row, index) => (
                                <div key={"row" + index} className="row pt-5">
                                    {row.map((campaign, i) => (
                                        <div key={"campaign" + i} className="col-md-4 mt-3 mt-sm-0">
                                            <CampaignBoxComponent {...campaign} />
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <RegisterCampaignModal brand_id={id} show={modal} onClose={() => setModal(false)}/>
    </>
}