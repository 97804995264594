import styled from "styled-components"

export const Container = styled.div`

  display: flex;
  align-items: center;
  
  .form-check-input {
    width: 4em;
    height: 2em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23FD9073'/%3e%3c/svg%3e");
    background-position: left center;
    border-radius: 2em;
    transition: background-position .15s ease-in-out;
    border: 2px solid var(--secondary-orange); 
    
    :checked {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
      background-position: right center;
      background-color: var(--primary-orange);
      border: 2px solid var(--secondary-orange);
    }
    
    :focus,
    :active {
      outline: none;
      border: 2px solid var(--secondary-orange);
      -webkit-box-shadow: none;
      box-shadow: none;
    }

    :focus {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23FD9073'/%3e%3c/svg%3e");
    }

    @media screen and (max-width: 768px) {
      width: 3em;
      height: 1.5em;
    }
    
  }
  
  label {
    font-weight: bold;
    font-size: 22px;
    padding: 0 10px;
    color: var(--tbase-blue);
    
    @media screen and (max-width: 768px) {
      font-size: 18px;
    }
  }
  
`