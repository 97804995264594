import React from "react"
import {LoginComponent} from "../components/Login"
import LoginImage from "../assets/svg/login.svg"

export const LoginPageComponent = () => (
    <div className="container-fluid">
        <div className="row">
            <div className="col-sm-12 col-md-12 col-xl-6">
                <div className="d-flex align-items-center position-relative vh-100">
                    <LoginComponent/>
                </div>
            </div>
            <div className="d-none d-lg-block col-6 space-top-3">
                <div className="d-flex align-items-center justify-content-start position-relative vh-100">
                    <img src={LoginImage} alt="Login T&BASE" className="img-fluid" style={{paddingLeft: "100px"}} />
                </div>
            </div>
        </div>
    </div>
)