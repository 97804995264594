import styled from "styled-components";

export const Container = styled.div`

  padding-top: 1rem;
  padding-left: 2rem;

  h1 {
    padding-top: 1rem;
    color: var(--tbase-blue);
    font-size: 1.6rem;
  }

  @media (max-width: 768px) {
    padding-left: 1rem;

    h1 {
      font-size: 1.2rem;
    }

    button {
      margin: 0 10px;
    }

  }

  

  h3 {
    color: var(--tbase-blue);
    margin: 0;
    text-align: right;
  }

  p {
    font-family: "Poppins", sans-serif;
    font-weight: bold;
    color: var(--tbase-orange);
    font-size: 25px;
    text-align: right;
  }

  img {
    width: 4rem;
    height: 4rem;
    top: 50%;
    object-fit: cover;
    border-radius: 50%;
  }

  hr {
    background-color: rgba(0,0,0,0.3);
    max-width: 100%;
    margin: 0;
  }
`
