import React, {useContext, useState} from "react"
import Select from "react-select"

import {AlertContext} from "../providers/AlertContext"
import {Modal} from "../components/Modal"
import {LoadingComponent} from "../components/Loading"
import {InputNormal, InputPassword} from "../components/Form"
import {DragAndDropComponent} from "../components/DragAndDrop"
import {Axios, customSelectStyles} from "../config"

export const RegisterUserModal = ({show, onClose}) => {

    const {successAlert, errorAlert} = useContext(AlertContext)
    const [loading, setLoading] = useState(false)

    const [name, setName] = useState("")
    const [lastname, setLastname] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [role, setRole] = useState()
    const [avatar, setAvatar] = useState([{name: ''}])

    const handleSubmit = () => {
        setLoading(true)
        const data = new FormData()
        data.append('name', name)
        data.append('lastname', lastname)
        data.append('email', email)
        data.append('role', role.value)
        data.append('password', password)
        if (avatar[0].name !== '') {
            data.append('profile_picture', avatar[0])
        }

        Axios.post("/users", data)
            .then((response) => {
                if (response.status === 201) {
                    setLoading(false)
                    successAlert("Correcto", "Usuario creado.", ()=>{window.location.reload()})
                }
            }).catch(() => {
            setLoading(false)
            errorAlert("Error", "No se pudo crear el usuario.")
        })
    }


    return (
        <Modal show={show} onClose={onClose} size="lg">
            {loading ? (
                <LoadingComponent />
            ) : (
                <div>
                    <h3>Registrar nuevo usuario</h3>
                    <p>La foto de perfil no es obligatoria.</p>

                    <div className="form-group mt-3">
                        <label>Nombre:</label>
                        <InputNormal type="text" className="w-100" value={name}
                                     onChange={(e) => setName(e.target.value)}/>
                    </div>

                    <div className="form-group mt-3">
                        <label>Apellidos:</label>
                        <InputNormal type="text" className="w-100" value={lastname}
                                     onChange={e => setLastname(e.target.value)}/>
                    </div>

                    <div className="form-group mt-3">
                        <label>Correo:</label>
                        <InputNormal type="email" className="w-100" value={email}
                                     onChange={e => setEmail(e.target.value)} />
                    </div>

                    <div className="form-group mt-3">
                        <label>Contraseña:</label>
                        <InputPassword className="w-100" value={password} onChange={e => setPassword(e.target.value)}/>
                    </div>


                    <div className="form-group mt-3">
                        <label htmlFor="">Rol:</label>
                        <Select
                            styles={customSelectStyles}
                            options={[
                                {label: "Administrador", value: "ROLE_ADMIN"},
                                {label: "Agente", value: "ROLE_AGENT"},
                                {label: "Supervisor", value: "ROLE_SUPERVISOR"}
                            ]}
                            placeholder={"Seleccionar estatus..."}
                            value={role}
                            onChange={e => setRole(e)}
                        />
                    </div>

                    <div className="form-group mt-3">
                        <label htmlFor="">Foto de perfil:</label>
                        <DragAndDropComponent handle={file => setAvatar(file)} files={avatar}/>
                    </div>

                    <div className="form-group mt-5">
                        <div className="d-flex justify-content-center">
                            <button className="btn-blue" onClick={handleSubmit}><i
                                className="fas fa-user-plus"/> Crear usuario
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </Modal>
    )
}