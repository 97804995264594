import React from "react"

import {BoxComponent} from "../../components/Box"

import LogoImage from "../../assets/svg/logo/logo.svg"
import ReportsStaticsImage from "../../assets/svg/reports-statics.svg"
import UserImage from "../../assets/svg/manage-users.svg"
import ManageCampaignsImage from "../../assets/svg/manage-campaigns.svg"

export const AdminPageComponent = () => {
    return (
        <div className="container">
            <div className="pt-5"/>
            <div className="d-flex justify-content-center pt-5">
                <img src={LogoImage} alt="Logo" className="img-fluid"/>
            </div>
            <h1 style={{"textAlign": "center"}} className="mt-5">Bienvenido, ¿Qué deseas hacer?</h1>
            <div className="row mt-5">
                <div className="col-md-4">
                    <div className="d-flex justify-content-center">
                        <BoxComponent image={ReportsStaticsImage} title="Reportes & Estadísticas"
                                      link="/reports-and-statics"/>
                    </div>
                </div>
                <div className="col-md-4 mt-3 mt-sm-0">
                    <div className="d-flex justify-content-center">
                        <BoxComponent image={UserImage} title="Administrar usuarios" link="/users"/>
                    </div>
                </div>
                <div className="col-md-4 mt-3 mt-sm-0">
                    <div className="d-flex justify-content-center">
                        <BoxComponent image={ManageCampaignsImage} title="Administrar campañas" link="/brands"/>
                    </div>
                </div>
            </div>
            <div className="pt-5 mt-5"/>
        </div>
    )
}