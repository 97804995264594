import React from "react"
import {Link} from "react-router-dom"

import {Container} from "./styles";

export const SurveyBoxComponent = ({link, title}) => (
    <Container>
        <Link to={link}>
            <div className="box">
                <div className="d-flex justify-content-center">
                    <span>{title}</span>
                </div>
            </div>
        </Link>
    </Container>
)